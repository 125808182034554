import React, { useEffect, useState } from "react";
import {
  DivNewProvider,
  FormProvider,
  DivOrgProvider,
  LabelProvider,
  InputProvider,
  LabelProviderStreet,
  InputProviderStreet,
  InputProviderNumber,
  InputMedium,
  SubmitForm,
  DivBtn,
  BtnRemove,
  SelectOption,
  Options,
  DivOrgLoading,
  DivOrgResults,
  InfoResult,
} from "./NewProviderStyle";
import { infoState } from "../../../utils/infoState.mjs";
import { useForm } from "react-hook-form";
import {
  createProvider,
  getCnpjProvider,
} from "../../../store/registers/provider/provider.actions";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { PatternFormat } from "react-number-format";

export default function NewProvider(props) {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, formState } = useForm();

  const [providerInfo, setProviderInfo] = useState([]);
  const [CNPJProvider, setCNPJProvider] = useState("");
  const [phoneProvider, setPhoneProvider] = useState("");
  const [localNumProvider, setLocalNumProvider] = useState("");
  const [providerErro, setProviderErro] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingProviders, setLoadingProviders] = useState(false);

  const newProvider = async (dataProvider) => {
    setLoading(true);
    dataProvider.cnpj = CNPJProvider;
    dataProvider.numberPhone = phoneProvider;
    dataProvider.localNumber = localNumProvider;
    const providerCreate = await dispatch(createProvider(dataProvider));
    setProviderInfo(providerCreate.payload);
    setTimeout(setLoading(false), 2000);
    setTimeout(() => {
      setLoadingProviders(true);
    }, 1500);
  };

  const verifyProvider = async (infoProvider) => {
    const cnpjProvider = await dispatch(getCnpjProvider(infoProvider));
    setProviderErro(cnpjProvider.payload);
  };

  useEffect(() => {
    if (loadingProviders) {
      props.getListProviders();
    }
    setTimeout(() => {
      setLoadingProviders(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingProviders]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      setTimeout(() => {
        reset();
        setLocalNumProvider("");
        setPhoneProvider("");
        setCNPJProvider("");
        setProviderInfo([]);
      }, 3000);
    }
  }, [
    reset,
    formState,
    setLocalNumProvider,
    setCNPJProvider,
    setPhoneProvider,
    setProviderInfo,
  ]);

  useEffect(() => {
    if (CNPJProvider.length === 14) {
      verifyProvider(CNPJProvider);
    } else {
      setProviderErro([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CNPJProvider]);

  useEffect(() => {
    if (providerErro?.codeStatus === 200) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [providerErro]);

  return (
    <DivNewProvider>
      <FormProvider onSubmit={handleSubmit(newProvider)}>
        <DivOrgProvider>
          <LabelProvider>Nome</LabelProvider>
          <InputProvider
            type="text"
            maxLength={100}
            {...register("nameProvider", {
              required: true,
            })}
          />
        </DivOrgProvider>
        <DivOrgProvider>
          <LabelProvider>Cnpj</LabelProvider>
          <PatternFormat
            customInput={InputMedium}
            value={CNPJProvider}
            format="##.###.###/####-##"
            allowEmptyFormatting
            mask="_"
            onValueChange={(values, sourceInfo) => {
              setCNPJProvider(values.value);
            }}
          />
        </DivOrgProvider>
        {providerErro.codeStatus === 200 && (
          <DivOrgResults>
            <InfoResult>Já existe um fornecedor com esse CNPJ</InfoResult>
          </DivOrgResults>
        )}
        <DivOrgProvider>
          <LabelProvider>Telefone</LabelProvider>
          <PatternFormat
            customInput={InputMedium}
            format="(##) #####-####"
            allowEmptyFormatting
            mask="_"
            value={phoneProvider}
            onValueChange={(values, sourceInfo) => {
              setPhoneProvider(values.value);
            }}
          />
        </DivOrgProvider>
        <DivOrgProvider>
          <LabelProviderStreet>Rua</LabelProviderStreet>
          <InputProviderStreet
            type="text"
            maxLength={70}
            {...register("street", {
              required: true,
            })}
          />
          <PatternFormat
            placeholder="Nº"
            customInput={InputProviderNumber}
            format="Nº #####"
            value={localNumProvider}
            onValueChange={(values, sourceInfo) => {
              setLocalNumProvider(values.value);
            }}
          />
        </DivOrgProvider>
        <DivOrgProvider>
          <LabelProvider>Bairro</LabelProvider>
          <InputMedium
            type="text"
            maxLength={50}
            {...register("district", {
              required: true,
            })}
          />
        </DivOrgProvider>
        <DivOrgProvider>
          <LabelProvider>Cidade</LabelProvider>
          <InputMedium
            type="text"
            maxLength={50}
            {...register("city", {
              required: true,
            })}
          />
        </DivOrgProvider>
        <DivOrgProvider>
          <LabelProvider>Estado</LabelProvider>
          <SelectOption {...register("state", { required: true })}>
            <Options value="">Selecione</Options>
            {infoState.map((infoStates, index) => {
              return (
                <Options key={index} value={infoStates.value}>
                  {infoStates.value}
                </Options>
              );
            })}
          </SelectOption>
        </DivOrgProvider>
        <DivBtn>
          <BtnRemove type="reset">Cancelar</BtnRemove>
          <SubmitForm type="submit" disabled={disableBtn}>
            Cadastrar
          </SubmitForm>
        </DivBtn>
      </FormProvider>
      {loading ? (
        <DivOrgLoading>
          <ClipLoader speedMultiplier={3} />
        </DivOrgLoading>
      ) : (
        (providerInfo.errorStatus && (
          <DivOrgResults>
            <InfoResult>{providerInfo.message}</InfoResult>
          </DivOrgResults>
        )) ||
        (providerInfo.successStatus && (
          <DivOrgResults>
            <InfoResult>{providerInfo.message}</InfoResult>
          </DivOrgResults>
        ))
      )}
    </DivNewProvider>
  );
}
