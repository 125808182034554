// import { Add, Delete } from "@styled-icons/material";
import React, { useEffect, useState } from "react";
import {
  DivNewProduct,
  FormProduct,
  DivFormProduct,
  DivOrgProduct,
  LabelProduct,
  LabelCalcResult,
  InputProduct,
  InputProductName,
  DivBtnProduct,
  BtnRemoveProduct,
  SubmitProduct,
  InputSmall,
  InputCodProduct,
  DivOrgResults,
  InfoResult,
  BtnCreateBrand,
  DivBtnBrand,
  DivOrgLoading,
  SelectOption,
  Options,
} from "./NewProductStyle";

import { useForm } from "react-hook-form";
import {
  createProduct,
  getProduct,
} from "../../../store/registers/products/products.actions";
import {
  getBrandInfo,
  createBrand,
} from "../../../store/registers/brand/brand.actions";
import { useDispatch } from "react-redux";
import { useDebounce } from "use-debounce";
import { NumericFormat, PatternFormat } from "react-number-format";
import { Add } from "@styled-icons/material";
import { ClipLoader } from "react-spinners";

export default function NewProduct(props) {
  const dispatch = useDispatch();
  const [priceBuyProduct, setPriceBuyProduct] = useState();
  const [percentSellProduct, setPercentSellProduct] = useState();

  const [nameBrand, setNameBrand] = useState();
  const [brandInfo, setBrandInfo] = useState([]);
  const [loadingBrand, setLoadingBrand] = useState(false);
  const [nameBrandSearch] = useDebounce(nameBrand, 500);
  const [loadingListProducts, setLoadingListProducts] = useState(false);
  const [loading, setLoading] = useState();

  const [codProduct, setCodProduct] = useState();
  const [productInfo, setProductInfo] = useState([]);
  const [productCreated, setProductCreated] = useState([]);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [productSearch] = useDebounce(codProduct, 500);
  const [buttonHide, setButtonHide] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      priceSell: "",
    },
  });

  const getBrand = async (dataBrand) => {
    setLoadingBrand(true);
    const nameBrandInfo = await dispatch(getBrandInfo(dataBrand));
    setBrandInfo(nameBrandInfo.payload);
    setLoadingBrand(false);
  };

  const getProductCod = async (dataProduct) => {
    setLoadingProduct(true);
    const nameProductInfo = await dispatch(getProduct(dataProduct));
    setProductInfo(nameProductInfo.payload);
    setLoadingProduct(false);
  };

  const newBrand = async (dataBrand) => {
    const brand = {
      brandName: dataBrand.toLowerCase(),
    };
    const brandCreate = await dispatch(createBrand(brand));

    setBrandInfo(brandCreate.payload);
  };

  const newProduct = async (dataProduct) => {
    setLoading(true);
    dataProduct.priceBuy = priceBuyProduct;
    dataProduct.percentSell = percentSellProduct;
    dataProduct.idBrand = brandInfo.brand.idBrand;
    const productCreate = await dispatch(createProduct(dataProduct));
    setProductCreated(productCreate.payload);
    setTimeout(() => {
      setLoadingListProducts(true);
      setLoading(false);
    }, 1000);
  };



  const disableScroll = (e) => {
    e.target.addEventListener(
      "wheel",
      function (e) {
        e.preventDefault();
      },
      { passive: false }
    );
  };

  const priceBuy = priceBuyProduct;
  const percentSell = percentSellProduct;

  useEffect(() => {
    if (productSearch?.length === 0) {
      setProductInfo(productSearch);
    }
    if (productSearch) {
      getProductCod(productSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSearch]);

  useEffect(() => {
    if (nameBrandSearch?.length === 0) {
      setBrandInfo(nameBrandSearch);
    }

    if (nameBrandSearch) {
      getBrand(nameBrandSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameBrandSearch]);

  useEffect(() => {
    let priceProduct =
      parseFloat(priceBuy) + parseFloat((priceBuy * percentSell) / 100);
    setValue("priceSell", priceProduct);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentSell, priceBuy, setValue]);

  useEffect(() => {
    if (productInfo.successStatus === true) {
      setButtonHide(true);
    } else {
      setButtonHide(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInfo]);

  useEffect(() => {
    // setLo
    if (loadingListProducts) {
      props.getListProducts();
    }
    setTimeout(() => {
      setLoadingListProducts(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingListProducts]);

  useEffect(() => {
    if (productCreated.codeStatus === 200) {
      setTimeout(() => {
        reset();
        setPriceBuyProduct("");
        setPercentSellProduct("");
      }, 3000);
      setTimeout(() => {
        setBrandInfo([]);
        // setBrandRegister([]);
        setProductCreated([]);
        setProductInfo([]);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCreated]);

  return (
    <DivNewProduct>
      <FormProduct onSubmit={handleSubmit(newProduct)}>
        <DivFormProduct>
          <DivOrgProduct>
            <LabelProduct>Codigo do Produto</LabelProduct>
            <InputCodProduct
              type="number"
              {...register("codProd")}
              onFocus={disableScroll}
              onChange={(e) => {
                setCodProduct(e.target.value);
              }}
            />
          </DivOrgProduct>
          {loadingProduct ? (
            <DivOrgLoading>
              <ClipLoader speedMultiplier={3} />
            </DivOrgLoading>
          ) : (
            productInfo.successStatus && (
              <DivOrgResults>
                <InfoResult>
                  Produto já cadastrado, use outro código para cadastrar esse
                  produto.
                </InfoResult>
              </DivOrgResults>
            )
          )}
          <DivOrgProduct>
            <LabelProduct>Nome</LabelProduct>
            <InputProductName
              type="text"
              maxLength={100}
              {...register("nameProduct")}
            />
          </DivOrgProduct>
          <DivOrgProduct>
            <LabelProduct>Validade ?</LabelProduct>
            <SelectOption
              {...register("dueDateOption", {
                // onChange: (e) => {
                //   setInputData(e.target.value);
                // },
              })}
            >
              <Options value="">Selecione</Options>
              <Options value="sim">Sim</Options>
              <Options value="nao">Não</Options>
            </SelectOption>
          </DivOrgProduct>
          <DivOrgProduct>
            <LabelProduct>Preço de Compra</LabelProduct>
            <NumericFormat
              customInput={InputProduct}
              value={priceBuyProduct}
              placeholder="R$"
              allowEmptyFormatting
              mask="_"
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={2}
              prefix={"R$"}
              onValueChange={(values, sourceInfo) => {
                setPriceBuyProduct(Number(values.value));
              }}
            />
          </DivOrgProduct>
          <DivOrgProduct>
            <LabelProduct>Porcentagem de Venda (%)</LabelProduct>
            <PatternFormat
              customInput={InputProduct}
              value={percentSellProduct}
              format="###%"
              allowEmptyFormatting
              mask="_"
              placeholder="%"
              isAllowed={(values) => {
                if (!values.value) return true;
                const { floatValue } = values;
                return floatValue <= 100;
              }}
              onValueChange={(values, sourceInfo) => {
                setPercentSellProduct(values.value);
              }}
            />
          </DivOrgProduct>
          <DivOrgProduct>
            <LabelProduct>Preço de Venda</LabelProduct>
            <NumericFormat
              placeholder=""
              customInput={LabelCalcResult}
              displayType="text"
              value={watch("priceSell") || 0}
              decimalSeparator=","
              fixedDecimalScale
              thousandSeparator="."
              decimalScale={2}
              prefix={"R$ "}
            />
            {/* <LabelCalcResult>{watch("priceSell") || 0}</LabelCalcResult> */}
          </DivOrgProduct>
          <DivOrgProduct>
            <LabelProduct>Estoque Minimo</LabelProduct>
            {/* Buscar um fonrecedor */}
            <InputSmall
              type="number"
              maxLength={6}
              {...register("minStock")}
              onFocus={disableScroll}
            />
            <LabelProduct>Estoque Maximo</LabelProduct>
            {/* Buscar um fonrecedor */}
            <InputSmall
              type="number"
              maxLength={6}
              {...register("maxStock")}
              onFocus={disableScroll}
            />
          </DivOrgProduct>
          <DivOrgProduct>
            <LabelProduct>Marca</LabelProduct>
            {/* Buscar uma marca */}
            <InputProduct
              {...register("idBrand", {})}
              onFocus={disableScroll}
              onChange={(e) => {
                setNameBrand(e.target.value);
              }}
            />
          </DivOrgProduct>
          {loadingBrand ? (
            <DivOrgLoading>
              <ClipLoader speedMultiplier={3} />
            </DivOrgLoading>
          ) : (
            brandInfo.errorStatus && (
              <DivOrgResults>
                <InfoResult>{brandInfo.message}</InfoResult>
                <DivBtnBrand>
                  <BtnCreateBrand
                    type="button"
                    onClick={() => newBrand(nameBrandSearch)}
                  >
                    <Add />
                  </BtnCreateBrand>
                </DivBtnBrand>
              </DivOrgResults>
            )
          )}
          {brandInfo.successStatus && (
            <DivOrgResults>
              <InfoResult>{brandInfo.message}</InfoResult>
            </DivOrgResults>
          )}
          <DivBtnProduct>
            <BtnRemoveProduct type="reset">Cancelar</BtnRemoveProduct>
            <SubmitProduct type="submit" disabled={buttonHide}>
              Cadastrar
            </SubmitProduct>
          </DivBtnProduct>
        </DivFormProduct>
      </FormProduct>
      {loading ? (
        <DivOrgLoading>
          <ClipLoader speedMultiplier={3} />
        </DivOrgLoading>
      ) : (
        (productCreated.errorStatus && (
          <DivOrgResults>
            <InfoResult>{productCreated.message}</InfoResult>
          </DivOrgResults>
        )) ||
        (productCreated.successStatus && (
          <DivOrgResults>
            <InfoResult>{productCreated.message}</InfoResult>
          </DivOrgResults>
        ))
      )}
    </DivNewProduct>
  );
}
