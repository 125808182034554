import React, { useState } from "react";
import {
  DivOrgCardDelete,
  DivOrgBtnClose,
  BtnClose,
  DivOrgLabel,
  DivOrgBtn,
  BtnConfirm,
  BtnCancel,
  LabelDelete,
  DivOrgDelete,
  InfoResult,
  DivOrgResults,
  DivOrgLoading,
} from "./DeleteProviderStyle";
import { Close } from "@styled-icons/material";
import { downProvider } from "../../../store/registers/provider/provider.actions";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";

export default function DeleteProvider(props) {
  const providerRemove = props.selectedProvider;
  const dispatch = useDispatch();
  const [deleteProviderInfo, setDeleteProviderInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  const deleteProvider = async (dataProvider) => {
    setLoading(true);
    const idProvider = dataProvider.idProvider;
    const deleteProvider = await dispatch(downProvider(idProvider));
    setDeleteProviderInfo(deleteProvider.payload);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    setTimeout(() => {
      props.setLoadingProviders(true);
      props.setDelProviderOption(false)
    }, 4000);
  };

  return (
    <DivOrgDelete>
      <DivOrgBtnClose>
        <BtnClose onClick={() => props.setDelProviderOption(false)}>
          <Close />
        </BtnClose>
      </DivOrgBtnClose>
      <DivOrgCardDelete showDiv={props.delProviderOption}>
        <DivOrgLabel>
          <LabelDelete>
            Deseja apagar o Fornecedor: {providerRemove.nameProvider}
          </LabelDelete>
        </DivOrgLabel>
        <DivOrgBtn>
          <BtnConfirm onClick={() => deleteProvider(providerRemove)}>
            Sim
          </BtnConfirm>
          <BtnCancel onClick={() => props.setDelProviderOption(false)}>
            Não
          </BtnCancel>
        </DivOrgBtn>
      </DivOrgCardDelete>
      {loading ? (
        <DivOrgLoading>
          <ClipLoader speedMultiplier={3} />
        </DivOrgLoading>
      ) : (
        (deleteProviderInfo.errorStatus && (
          <DivOrgResults>
            <InfoResult>{deleteProviderInfo.message}</InfoResult>
          </DivOrgResults>
        )) ||
        (deleteProviderInfo.successStatus && (
          <DivOrgResults>
            <InfoResult>{deleteProviderInfo.message}</InfoResult>
          </DivOrgResults>
        ))
      )}
    </DivOrgDelete>
  );
}
