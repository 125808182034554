import React, { useEffect, useState } from "react";
import { DivUserRegister, TitleUser, DivScreenUser } from "./UserRegisterStyle";
import NewUser from "../../components/Forms/NewUser/NewUser";
import SearchUser from "../../components/Search/SearchUser/SearchUser";

import { useDispatch } from "react-redux";
import { allUsers } from "../../store/registers/users/user.actions";

export default function UserRegister() {
  const dispatch = useDispatch();
  const [permissions, setPermissions] = useState([]);
  const [usersInfo, setUsersInfo] = useState([]);
  const [disableFilter, setDisableFilter]= useState(false)

  const getListUsers = async () => {
    const listUsers = await dispatch(allUsers());

    setPermissions(listUsers.payload.allPermissions);
    setUsersInfo(listUsers.payload);
  };

  useEffect(() => {
    getListUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (usersInfo.errorStatus === true) {
      alert(usersInfo.message);
      setDisableFilter(true)
    } else {
      setDisableFilter(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersInfo.errorStatus]);

  return (
    <DivUserRegister>
      <TitleUser>Usuario</TitleUser>
      <DivScreenUser>
        <NewUser getListUsers={getListUsers} permissions={permissions} />
        <SearchUser
          usersInfo={usersInfo?.users}
          getListUsers={getListUsers}
          permissions={permissions}
          disableFilter={disableFilter}
        />
      </DivScreenUser>
    </DivUserRegister>
  );
}
