import React, { useEffect, useState } from "react";
import {
  DivItemStockEntry,
  FormItemStockEntry,
  DivOrgItemStockEntry,
  LabelItemStockEntry,
  InputBig,
  InputSmall,
  InputDate,
  LabelResult,
  LabelCalcResult,
  SelectOption,
  Options,
  SubmitFormItemStockEntry,
  DivOrgResults,
  InfoResults,
  DivOrgInput,
  DivOrgScreen,
  DivAlerts,
  Alerts,
  TitleAlert,
  Line,
  TitleDelivery,
  DivOrgBtnCancel,
  BtnCancel,
} from "./NewItemStyle";
import TableItem from "../../Tables/TableItem/TableItem";

import { useDebounce } from "use-debounce";
import { useDispatch } from "react-redux";
import { getProduct } from "../../../store/registers/products/products.actions";
import { getCnpjProvider } from "../../../store/registers/provider/provider.actions";
import { idProductStock } from "../../../store/stock/stockNow/stockNow.actions";

import { useForm } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import { NumericFormat, PatternFormat } from "react-number-format";
import { Cancel } from "@styled-icons/material";

export default function NewItem() {
  const dispatch = useDispatch();
  const [priceUnitFormated, setPriceUnitFormated] = useState("");
  const [productStock, setProductStock] = useState([]);
  const [stockInfo, setStockInfo] = useState();
  const [productId, setProductId] = useState(0);
  const [productInfo, setProductInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [productSearch] = useDebounce(productId, 300);
  const [showAlert, setShowAlert] = useState(false);
  const [valueDelivery, setValueDelivery] = useState(0);
  const [infoQtd, setInfoQtd] = useState(0);
  const [batchOption, setBatchOption] = useState();

  // const [inputData, setInputData] = useState("");
  const [inputDelivery, setInputDelivery] = useState(0);
  const [inputQtd, setInputQtd] = useState(false);
  const [btnConfirm, setBtnConfirm] = useState(false);

  const [providerCnpj, setProviderCnpj] = useState("");
  const [providerInfo, setProviderInfo] = useState([]);
  const [loadingProvider, setLoadingProvider] = useState(false);
  const [blockProvider, setBlockProvider] = useState(false);

  const [providerSearch] = useDebounce(providerCnpj, 300);
  const { register, handleSubmit, watch, setValue, reset, formState } = useForm(
    {
      defaultValues: {
        priceTotal: 0,
      },
    }
  );

  const getProductInfo = async (idProduct) => {
    setLoading(true);
    const valueIdProduct = idProduct;
    const getProductId = await dispatch(getProduct(valueIdProduct));

    setProductInfo(getProductId.payload);
    setTimeout(setLoading(false), 1000);
  };

  const getProvider = async (cnpjValue) => {
    setLoadingProvider(true);
    const providerCnpj = cnpjValue;
    const provider = await dispatch(getCnpjProvider(providerCnpj));
    setProviderInfo(provider.payload);

    setLoadingProvider(false);
  };

  // Aqui faz o envio de um ojbeto e retorna o valor inicial
  function sendProductToStock(dataProduct) {
    // envia o valor unitario do item
    dataProduct.priceUnit = Number(priceUnitFormated);
    // enviar o id do produto
    dataProduct.idProduct = productInfo.product.idProduct;
    // enviar o id do fornecedor
    dataProduct.idProvider = providerInfo.provider.idProvider;
    // envia o valor do delivery
    dataProduct.valueDelivery = Number(valueDelivery);
    //Envia o nome do produto
    dataProduct.nameProduct = productInfo.product.nameProduct;
    // coloca a opção de validade
    dataProduct.dueDateOption = batchOption;
    //Envia a quantidade
    dataProduct.newQtd = Number(infoQtd);

    if (dataProduct.productBatch === "") {
      delete dataProduct.productBatch;
      dataProduct.dueDateProduct = "sem vencimento";
    }
    // // ! - Verificar se os dados estão ok
    // if (dataProduct.idProduct === undefined) {
    //   window.alert("produto não encontrado");
    // }
    // if (dataProduct.idProvider === undefined) {
    //   window.alert("fornecedor não encontrado");
    // } else {
      setProductStock(dataProduct);
    // }
  }

  const newQtd = Number(infoQtd);
  const priceUnit = Number(priceUnitFormated);

  const limitQtd = async (newQtdAdd) => {
    const idProduct = productInfo.product.codProd || 0;
    let qtdStock;
    let productStockNow;
    let productInStock;
    if (idProduct > 0 && newQtdAdd > 0) {
      productStockNow = await dispatch(idProductStock(idProduct));

      if (productStockNow.payload.codeStatus === 404) {
        qtdStock = 0;
      }
      if (productStockNow.payload.codeStatus === 200) {
        productInStock = productStockNow.payload.productStock;

        qtdStock = productInStock.totalQtd;
      }
      setStockInfo(qtdStock);
      let limitMaxProduct = productInfo.product.maxStock;
      let addStock = qtdStock + parseInt(newQtdAdd);
      if (addStock > limitMaxProduct || qtdStock > limitMaxProduct) {
        setBtnConfirm(true);
        setShowAlert(true);
      } else {
        setBtnConfirm(false);
        setShowAlert(false);
      }
    }
  };

  const changeProvider = () => {
    setLoadingProvider(true);
    setProviderInfo([]);
    setProviderCnpj("");
    setBlockProvider(false);

    setTimeout(() => {
      setLoadingProvider(false);
    }, 500);
  };

  const disableScroll = (e) => {
    e.target.addEventListener(
      "wheel",
      function (e) {
        e.preventDefault();
      },
      { passive: false }
    );
  };

  useEffect(() => {
    let totalPrice;

    if (newQtd > 0) {
      totalPrice = newQtd * priceUnit;
      if (totalPrice) {
        setValue("priceTotal", Number(totalPrice));
      } else {
        setValue("priceTotal", 0);
      }
    }
    // getProductInfo(productSearch)
  }, [newQtd, priceUnit, setValue]);

  useEffect(() => {
    if (providerInfo.codeStatus === 200) {
      setBlockProvider(true);
    }
  }, [providerInfo]);

  useEffect(() => {
    if (productSearch.length === 0) {
      setProductInfo(productSearch);
      setBatchOption();
      setLoading(false);
    }
    if (productSearch.length > 0) {
      getProductInfo(productSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSearch]);

  useEffect(() => {
    if (providerSearch.length === 0) {
      setProviderInfo([]);
    }
    if (providerSearch.length === 14) {
      getProvider(providerSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerSearch]);

  useEffect(() => {
    if (productInfo.codeStatus === 200) {
      setBatchOption(productInfo.product.dueDateOption);
    }
  }, [productInfo]);

  useEffect(() => {
    // if(productInfo.product)
    if (productInfo.successStatus) {
      setInputQtd(false);
    } else {
      setInputQtd(true);
    }
  }, [productInfo.successStatus, setInputQtd]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      setTimeout(() => {
        reset();
        setPriceUnitFormated("");
        setStockInfo([]);
        setBatchOption();
        setProductInfo([]);
        setProductId(0);
        setLoading(false);
      }, 1500);
    }
  }, [formState, reset]);

  return (
    <DivOrgScreen>
      <DivItemStockEntry>
        <FormItemStockEntry onSubmit={handleSubmit(sendProductToStock)}>
          <DivOrgItemStockEntry>
            <LabelItemStockEntry>Codigo do Produto</LabelItemStockEntry>
            <InputBig
              type="number"
              {...register("codProd")}
              onChange={(e) => setProductId(e.target.value)}
              onFocus={disableScroll}
            />
          </DivOrgItemStockEntry>
          {productInfo.errorStatus && (
            <DivOrgResults>
              <InfoResults>{productInfo.message}</InfoResults>
            </DivOrgResults>
          )}
          <DivOrgItemStockEntry>
            <LabelItemStockEntry>Produto</LabelItemStockEntry>
            {/* Somente Exibição */}
            <LabelResult>
              {loading ? (
                <ClipLoader speedMultiplier={3} />
              ) : (
                productInfo?.product?.nameProduct || "Nome do Produto"
              )}
            </LabelResult>
          </DivOrgItemStockEntry>

          {batchOption === "sim" ? (
            <DivOrgItemStockEntry>
              <DivOrgInput>
                {/* Acrescentar validação quando o item tiver lote e validade */}
                <LabelItemStockEntry>Lote</LabelItemStockEntry>
                <InputSmall type="text" {...register("productBatch")} />
              </DivOrgInput>
              <DivOrgInput>
                <LabelItemStockEntry>Vencimento</LabelItemStockEntry>
                <InputDate type="date" {...register("dueDateProduct")} />
              </DivOrgInput>
            </DivOrgItemStockEntry>
          ) : (
            <></>
          )}
          <DivOrgItemStockEntry>
            <DivOrgInput>
              <LabelItemStockEntry>Quantidade</LabelItemStockEntry>
              <InputSmall
                disabled={inputQtd}
                type="number"
                step="any"
                onChange={(e) => {
                  limitQtd(e.target.value);
                  setInfoQtd(e.target.value);
                }}
                onFocus={disableScroll}
              />
            </DivOrgInput>
            <DivOrgInput>
              <LabelItemStockEntry>Medida</LabelItemStockEntry>
              <SelectOption
                {...register("measure", {
                  // required: true,
                })}
              >
                <Options value="select">Selectione</Options>
                <Options value="un">UN</Options>
                <Options value="lt">LT</Options>
                <Options value="kg">KG</Options>
              </SelectOption>
            </DivOrgInput>
          </DivOrgItemStockEntry>
          <DivOrgItemStockEntry>
            <DivOrgInput>
              {/* colocar para utilizar valores em decimal e formatar R$00.000.00*/}
              <LabelItemStockEntry>Preço Unitario</LabelItemStockEntry>
              <NumericFormat
                value={priceUnitFormated}
                customInput={InputSmall}
                placeholder="R$ "
                allowEmptyFormatting
                mask="_"
                decimalSeparator=","
                fixedDecimalScale
                thousandSeparator="."
                decimalScale={2}
                prefix={"R$"}
                onValueChange={(values, sourceInfo) => {
                  setPriceUnitFormated(Number(values.value));
                }}
              />
            </DivOrgInput>
            <DivOrgInput>
              <LabelItemStockEntry>Preço Total</LabelItemStockEntry>
              {/* Label do calc de preço unit x newQtd e formatar para 
              var f = atual.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});*/}
              {/* <LabelCalcResult>R$ {watch("priceTotal" || 0)}</LabelCalcResult> */}
              <NumericFormat
                customInput={LabelCalcResult}
                displayType="text"
                value={watch("priceTotal")}
                decimalSeparator=","
                thousandSeparator="."
                fixedDecimalScale
                decimalScale={2}
                prefix={"R$ "}
              />
            </DivOrgInput>
          </DivOrgItemStockEntry>
          <DivOrgItemStockEntry>
            {/* Criar mascara para CNPJ */}
            <LabelItemStockEntry>CNPJ</LabelItemStockEntry>
            <PatternFormat
              disabled={blockProvider}
              customInput={InputBig}
              value={providerCnpj}
              format="##.###.###/####-##"
              mask="_"
              allowEmptyFormatting
              onValueChange={(values, sourceInfo) => {
                setProviderCnpj(values.value);
              }}
            />
            {providerInfo.codeStatus === 200 && (
              <DivOrgBtnCancel>
                <BtnCancel
                  type="button"
                  onClick={() => {
                    changeProvider();
                  }}
                >
                  <Cancel />
                </BtnCancel>
              </DivOrgBtnCancel>
            )}
          </DivOrgItemStockEntry>
          {providerInfo.errorStatus && (
            <DivOrgResults>
              <InfoResults>{providerInfo.message}</InfoResults>
            </DivOrgResults>
          )}
          <DivOrgItemStockEntry>
            <LabelItemStockEntry>Fornecedor do Produto</LabelItemStockEntry>
            {/* Busca do fornecedor para exibição */}
            <LabelResult>
              {loadingProvider ? (
                <ClipLoader speedMultiplier={3} />
              ) : (
                providerInfo?.provider?.nameProvider || "Nome do Fornecedor"
              )}
            </LabelResult>
          </DivOrgItemStockEntry>
          <Line />
          <TitleDelivery>Valor do Frete</TitleDelivery>
          <DivOrgItemStockEntry>
            <DivOrgInput>
              <LabelItemStockEntry>Frete</LabelItemStockEntry>
              <SelectOption
                {...register("statusDelivery", {
                  // required: true,
                  onChange: (e) => {
                    setInputDelivery(e.target.value);
                  },
                })}
              >
                <Options value="">Selecionar</Options>
                <Options value="sim">Sim</Options>
                <Options value="nao">Não</Options>
              </SelectOption>
            </DivOrgInput>
            {inputDelivery === "sim" && (
              <DivOrgInput>
                <LabelItemStockEntry>Valor</LabelItemStockEntry>
                <NumericFormat
                  placeholder="R$ "
                  customInput={InputSmall}
                  decimalSeparator=","
                  thousandSeparator="."
                  decimalScale={2}
                  prefix={"R$"}
                  onValueChange={(values, sourceInfo) => {
                    setValueDelivery(values.value);
                  }}
                />
              </DivOrgInput>
            )}
            {/* <InputSmall
              type="number"
              {...register("valueDelivery", {
                // required: true,
              })}
              onFocus={disableScroll}
            /> */}
          </DivOrgItemStockEntry>
          {showAlert && (
            <DivAlerts>
              <TitleAlert>AVISO!!</TitleAlert>
              <Alerts>
                A nova quantidade passa do limite permitido de{" "}
                {productInfo.product.maxStock} unidades, quantidade atual é{" "}
                {stockInfo}
              </Alerts>
            </DivAlerts>
          )}
          <SubmitFormItemStockEntry disabled={btnConfirm} type="submit">
            Adicionar
          </SubmitFormItemStockEntry>
        </FormItemStockEntry>
      </DivItemStockEntry>
      {/* Passa um objeto, resetar os inputs apos enviar */}
      <TableItem productStock={productStock} />
    </DivOrgScreen>
  );
}
