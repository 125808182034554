import React, { useEffect, useState } from "react";
import {
  DivOrder,
  TitleOrder,
  OrderForm,
  InputBig,
  Label,
  LabelInfo,
  BtnForm,
  DivOrg,
  DivTotal,
  DivOrgResults,
  InfoResult,
  DivOrgScreen,
  BtnCancel,
  InputSmall,
  DivOrgClient,
  DivOrgBtnCancel,
  DivOrgLoading,
  SelectDoc,
  Options,
  DivAlerts,
  TitleAlert,
  Alerts,
} from "./OrderStyle";
import DetailOrder from "../../Details/DetailOrder/DetailOrder";

import { ClipLoader } from "react-spinners";
import { useForm } from "react-hook-form";
import { getProduct } from "../../../store/registers/products/products.actions";
import { getClient } from "../../../store/registers/clients/clients.actions";
import { useDispatch } from "react-redux";
import { useDebounce } from "use-debounce";
import { NumericFormat, PatternFormat } from "react-number-format";
import { Cancel } from "@styled-icons/material";

export default function Order(props) {
  const dispatch = useDispatch();
  // items para alterações
  const infoOrderChange = props.infoOrderChange;
  const infoClientChange = props.infoOrderChange;

  // Cart states guide
  const [cartItem, setCartItem] = useState([]);
  const [infoClient, setInfoClient] = useState([]);
  // Product States guide
  const [productId, setProductId] = useState(0);
  const [productsInfo, setProductsInfo] = useState([]);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [productSearch] = useDebounce(productId, 300);
  const [percentDiscount, setPercentDiscount] = useState(0);
  const [showAlertQtd, setShowAlertQtd] = useState(false);
  const [qtdSell, setQtdSell] = useState(0);
  const [disableBtn, setDisableBtn] = useState(false);

  const [showAlertZero, setShowAlertZero] = useState(false);

  // Client State guide
  const [clientId, setClientId] = useState("");
  const [selectDoc, setSelectDoc] = useState("cpf");
  const [blockClient, setBlockClient] = useState(false);
  const [clientsInfo, setClientsInfo] = useState([]);
  const [loadingClient, setLoadingClient] = useState(false);
  const [clientSearch] = useDebounce(clientId, 300);
  const { register, handleSubmit, watch, setValue, reset, formState } = useForm(
    {
      defaultValues: {
        priceWithDiscount: 0,
        priceNoDiscount: 0,
        valueDiscount: 0,
      },
    }
  );

  const getIdProduct = async (idProduct) => {
    setLoadingProduct(true);
    const valueProduct = idProduct;
    const productId = await dispatch(getProduct(valueProduct));

    setProductsInfo(productId.payload);
    setLoadingProduct(false);
  };

  const getIdClient = async (idClient) => {
    setLoadingClient(true);
    const valueClient = idClient;
    const client = await dispatch(getClient(valueClient));

    setClientsInfo(client.payload);
    setTimeout(() => {
      setLoadingClient(false);
    }, 500);
  };

  function parseName(oneName, secondName) {
    const firstName = oneName || " ";
    const lastName = secondName || " ";
    var fullName = firstName.concat(" ", lastName) || "";
    const formatName = fullName.split(" ");
    for (var i = 0; i < formatName.length; i++) {
      formatName[i] =
        formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
    }
    const result = formatName.join(" ");

    return result;
  }

  const changeClient = () => {
    setLoadingClient(true);
    setClientsInfo([]);
    setClientId("");
    setBlockClient(false);

    setTimeout(() => {
      setLoadingClient(false);
    }, 500);
  };

  function sendProductToCheckout(dataProduct) {
    // preço unitarios
    dataProduct.priceUnit = parseFloat(priceUnit);
    dataProduct.percentDiscount = discount;
    dataProduct.nameProduct = nameProduct;

    if (dataProduct.codProd === undefined) {
      console.log("Produto não encontrado");
    } else {
      if (clientsInfo?.client?.clientName === undefined) {
        setInfoClient({
          nameClient: "Cliente Não identificado",
        });
      } else {
        const clientNameFormat = parseName(
          clientsInfo?.client?.clientName,
          clientsInfo?.client?.lastName
        );
        setInfoClient({
          nameClient: clientNameFormat,
          cpf: clientsInfo.client.cpf,
          idClient: clientsInfo.client.idClient,
          city: clientsInfo.client.city,
          district: clientsInfo.client.district,
          street: clientsInfo.client.street,
          localNumber: clientsInfo.client.localNumber,
          state: clientsInfo.client.state,
          complement: clientsInfo.client.complement,
        });
      }

      setCartItem(dataProduct);
    }
  }

  //
  const disableScroll = (e) => {
    e.target.addEventListener(
      "wheel",
      function (e) {
        e.preventDefault();
      },
      { passive: false }
    );
  };

  let qtd = watch("qtd");
  // priceunit vai receber o valor do produto ao buscar no banco product.value
  let priceUnit = productsInfo?.product?.priceSell.toFixed(2);
  let nameProduct = productsInfo?.product?.nameProduct;
  let discount = Number(percentDiscount);

  useEffect(() => {
    let totalPrice;
    if (discount > 0) {
      const priceNoDiscount = qtd * priceUnit;
      const valueDiscount = parseFloat(
        (qtd * priceUnit * discount) / 100
      ).toFixed(2);
      totalPrice =
        qtd * priceUnit - parseFloat((qtd * priceUnit * discount) / 100 || "0");
      setValue("priceWithDiscount", Number(totalPrice.toFixed(2)));
      setValue("priceNoDiscount", priceNoDiscount.toFixed(2));
      setValue("valueDiscount", Number(valueDiscount));
    } else {
      totalPrice = qtd * priceUnit;
      setValue("valueDiscount", 0);
      setValue("priceWithDiscount", Number(totalPrice.toFixed(2)));
      setValue("priceNoDiscount", Number(totalPrice.toFixed(2)));
    }
    // setValue("totalValue", totalPrice);
  }, [qtd, discount, setValue, priceUnit]);

  useEffect(() => {
    if (productSearch?.length === 0) {
      setProductsInfo(productSearch);
    }
    if (productSearch) {
      getIdProduct(productSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSearch]);

  useEffect(() => {
    if (clientSearch?.length === 0) {
      setClientsInfo(clientSearch);
    }
    if (clientSearch) {
      getIdClient(clientSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSearch]);

  useEffect(() => {
    if (clientsInfo.codeStatus === 200) {
      setBlockClient(true);
    }
  }, [clientsInfo]);

  useEffect(() => {
    if (infoClientChange?.detailOrder?.infoClient?.nameClient.length > 0) {
      setLoadingClient(true);
      setClientId(infoClientChange?.detailOrder.infoClient.cpf);
      setClientsInfo([]);
      setInfoClient(infoClientChange?.detailOrder?.infoClient);
      setBlockClient(false);
      setTimeout(() => {
        setLoadingClient(false);
      }, 500);
    }
  }, [infoClientChange]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      setTimeout(() => {
        reset();
        // setProductsInfo([])
        setPercentDiscount("");
      }, 1500);
    }
  }, [formState, reset]);

  useEffect(() => {
    if (qtdSell > productsInfo?.product?.qtdNow) {
      setShowAlertQtd(true);
      setDisableBtn(true);
    } else {
      setShowAlertQtd(false);
      setDisableBtn(false);
    }
  }, [productsInfo, qtdSell]);

  useEffect(() => {
    if (productsInfo?.product?.qtdNow === 0) {
      setShowAlertZero(true);
      setDisableBtn(true);
    } else {
      setShowAlertZero(false);
      setDisableBtn(false);
    }
  }, [productsInfo]);

  return (
    <DivOrgScreen>
      <DivOrder>
        <TitleOrder>Adicionar Produto</TitleOrder>
        <OrderForm
          autoComplete="off"
          onSubmit={handleSubmit(sendProductToCheckout)}
        >
          <DivOrgClient>
            <Label>
              <SelectDoc onChange={(e) => setSelectDoc(e.target.value)}>
                <Options value="cpf">CPF</Options>
                <Options value="cnpj">CNPJ</Options>
              </SelectDoc>
              Cliente
            </Label>
            {selectDoc === "cpf" ? (
              <PatternFormat
                disabled={blockClient}
                value={clientId}
                customInput={InputBig}
                format="###.###.###-##"
                allowEmptyFormatting
                mask="_"
                onValueChange={(values, sourceInfo) => {
                  setClientId(values.value);
                }}
              />
            ) : (
              <PatternFormat
                disabled={blockClient}
                value={clientId}
                customInput={InputBig}
                format="##.###.###/####-##"
                allowEmptyFormatting
                mask="_"
                onValueChange={(values, sourceInfo) => {
                  setClientId(values.value);
                }}
              />
            )}

            {clientsInfo.codeStatus === 200 && (
              <DivOrgBtnCancel>
                <BtnCancel
                  type="button"
                  onClick={() => {
                    changeClient();
                  }}
                >
                  <Cancel />
                </BtnCancel>
              </DivOrgBtnCancel>
            )}
          </DivOrgClient>
          <DivOrg>
            <Label>Nome Cliente</Label>
            {/* <DivOrgLoading>
                <ClipLoader speedMultiplier={3} color={"#000"} />
              </DivOrgLoading> */}
            {loadingClient ? (
              <DivOrgLoading>
                <ClipLoader speedMultiplier={3} color={"#000"} />
              </DivOrgLoading>
            ) : (
              (clientsInfo.codeStatus === 404 && (
                <LabelInfo>{clientsInfo.message}</LabelInfo>
              )) ||
              (clientsInfo.codeStatus === 200 &&
                parseName(
                  clientsInfo?.client?.clientName,
                  clientsInfo?.client?.lastName
                ))
            )}
          </DivOrg>
          <DivOrg>
            <Label>Codigo Produto</Label>
            <InputBig
              onFocus={disableScroll}
              type="number"
              // placeholder="Codigo"
              {...register("codProd")}
              onChange={(e) => setProductId(e.target.value)}
            />
          </DivOrg>
          {productsInfo.errorStatus && (
            <DivOrgResults>
              <InfoResult>{productsInfo.message}</InfoResult>
            </DivOrgResults>
          )}

          <DivOrg>
            <Label>Nome Produto</Label>
            {/* nome do produto {product.name} */}
            <LabelInfo>
              {loadingProduct ? (
                <ClipLoader speedMultiplier={3} />
              ) : (
                productsInfo?.product?.nameProduct || "-"
              )}
            </LabelInfo>
          </DivOrg>
          <DivOrg>
            <Label>Preço</Label>
            {/* Valor do produto {product.value} */}
            <LabelInfo>
              {loadingProduct ? (
                <ClipLoader speedMultiplier={3} />
              ) : (
                <NumericFormat
                  value={productsInfo?.product?.priceSell || 0}
                  placeholder=""
                  customInput={LabelInfo}
                  displayType="text"
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale
                  decimalScale={2}
                  prefix={"R$ "}
                />
              )}
            </LabelInfo>
          </DivOrg>
          <DivOrg>
            <Label>Quantidade</Label>
            <InputSmall
              onFocus={disableScroll}
              type="number"
              step="any"
              placeholder="Quantidade"
              {...register("qtd", {
                valueAsNumber: true,
                required: true,
                onChange: (e) => {
                  setQtdSell(e.target.value);
                },
              })}
            />
          </DivOrg>
          <DivOrg>
            <Label>Desconto (%)</Label>
            <NumericFormat
              value={percentDiscount}
              customInput={InputSmall}
              decimalSeparator="."
              decimalScale={2}
              placeholder="%"
              suffix="%"
              isAllowed={(values) => {
                if (!values.value) return true;
                const { floatValue } = values;
                return floatValue <= 100;
              }}
              onValueChange={(values, sourceInfo) => {
                setPercentDiscount(values.value);
              }}
            />
          </DivOrg>
          {showAlertQtd && (
            <DivAlerts>
              <TitleAlert>AVISO!!</TitleAlert>
              <Alerts>
                A quantidade a ser vendida é maior que o estoque atual de{" "}
                {productsInfo?.product?.qtdNow}
              </Alerts>
            </DivAlerts>
          )}
          {showAlertZero && (
            <DivAlerts>
              <TitleAlert>AVISO!!</TitleAlert>
              <Alerts>
                Esse item está com quantidade zero no estoque, a venda não pode
                ser realizada
              </Alerts>
            </DivAlerts>
          )}
          <DivTotal>
            <Label>Total</Label>
            <NumericFormat
              placeholder=""
              customInput={LabelInfo}
              displayType="text"
              value={watch("priceWithDiscount") || 0}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale
              decimalScale={2}
              prefix={"R$ "}
            />
            {/* <LabelInfo>{watch("totalValue") || 0}</LabelInfo> */}
          </DivTotal>
          <BtnForm type="submit" disabled={disableBtn}>
            Adicionar
          </BtnForm>
        </OrderForm>
      </DivOrder>
      <DetailOrder
        cartItem={cartItem}
        infoClient={infoClient}
        infoOrderChange={infoOrderChange}
      />
    </DivOrgScreen>
  );
}
