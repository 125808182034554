import React from "react";
import {
  DivStockItem,
  TitleStockItem,
  DivScreenStockItem,
} from "./StockItemStyles";
import NewItem from "../../components/Forms/NewItem/NewItem";

export default function StockItem() {
  return (
    <DivStockItem>
      <TitleStockItem>Adicionar Item no Estoque</TitleStockItem>
      <DivScreenStockItem>
        {/* Eu posso juntar os dois componentes em caso de passar props entre eles */}
        <NewItem />
      </DivScreenStockItem>
    </DivStockItem>
  );
}
