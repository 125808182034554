import React, { useEffect, useState } from "react";
import { DivBrand, TitleBrand, DivScreenBrand } from "./BrandRegisterStyle";
import NewBrand from "../../components/Forms/NewBrand/NewBrand";
import SearchBrand from "../../components/Search/SearchBrand/SearchBrand";

import { useDispatch } from "react-redux";
import { getAllBrand } from "../../store/registers/brand/brand.actions";

export default function BrandRegister() {
  const dispatch = useDispatch();
  const [brandsInfo, setBrandsInfo] = useState([]);
  const [disableFilter, setDisableFilter] = useState(false);

  const getListBrands = async () => {
    const listBrand = await dispatch(getAllBrand());
    setBrandsInfo(listBrand.payload);
  };

  useEffect(() => {
    getListBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (brandsInfo.errorStatus === true) {
      alert(brandsInfo.message);
      setDisableFilter(true);
    } else {
      setDisableFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandsInfo.errorStatus]);
  return (
    <DivBrand>
      <TitleBrand>Marca</TitleBrand>
      <DivScreenBrand>
        <NewBrand getListBrands={getListBrands} />
        <SearchBrand
          brandsInfo={brandsInfo.brand}
          getListBrands={getListBrands}
          disableFilter={disableFilter}
        />
      </DivScreenBrand>
    </DivBrand>
  );
}
