import React, { useEffect, useState } from "react";
import {
  DivDetailStock,
  TitleDetailStock,
  DivTableDetailStock,
  TableDetailStock,
  DivCardDetailsProduct,
  InfoProduct,
  InfoQtd,
  InfoPrice,
  InfoDate,
  DivBtnClose,
  BtnClose,
  DivOrgTitle,
  DivOrgInfo,
  DivOrgDate,
  DivOrgLoading,
  InfoBatch,
} from "./DetailStockStyle";
import { ClipLoader } from "react-spinners";
import { Close } from "@styled-icons/material";

export default function DetailStock(props) {
  const infoStock = props.selectedProduct;

  const [loading, setLoading] = useState();
  const [listStock, setListStock] = useState([]);


  const createListStock = async (dataStock) => {
    setLoading(true);
    setListStock(dataStock);
  };

  useEffect(() => {
    createListStock(infoStock.infoProduct);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [infoStock.infoProduct]);


  return (
    <DivDetailStock show={props.stockPopUp}>
      <DivBtnClose>
        <DivOrgTitle>
          <TitleDetailStock>
            Detalhes do produto: {infoStock.nameProduct} no estoque atual
          </TitleDetailStock>
        </DivOrgTitle>
        <BtnClose onClick={() => props.setStockPopUp(false)}>
          <Close />
        </BtnClose>
      </DivBtnClose>
      <DivTableDetailStock>
        <TableDetailStock>
          {loading ? (
            <DivOrgLoading>
              <ClipLoader speedMultiplier={3} color="#FFF" />
            </DivOrgLoading>
          ) : (
            listStock.map((infoStockProduct, index) => {
              return (
                <DivCardDetailsProduct key={index}>
                  <DivOrgInfo>
                    <InfoProduct>Qtd</InfoProduct>
                    <InfoQtd>{infoStockProduct.totalQtdByProduct}</InfoQtd>
                  </DivOrgInfo>
                  <DivOrgInfo>
                    <InfoProduct>Preço Unitario</InfoProduct>
                    <InfoPrice
                      displayType="text"
                      value={infoStockProduct.priceUnit.toLocaleString(
                        "pt-BR",
                        { style: "currency", currency: "BRL" }
                      )}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale
                      decimalScale={2}
                      prefix={"R$ "}
                    />
                  </DivOrgInfo>
                  <DivOrgInfo>
                    <InfoProduct>Preço Total</InfoProduct>
                    <InfoPrice
                      displayType="text"
                      value={infoStockProduct.priceTotalByProduct.toLocaleString(
                        "pt-BR",
                        { style: "currency", currency: "BRL" }
                      )}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale
                      decimalScale={2}
                      prefix={"R$ "}
                    />
                  </DivOrgInfo>
                  <DivOrgDate>
                    <InfoProduct>Vencimento</InfoProduct>
                    <InfoDate>{infoStockProduct.dueDateProduct}</InfoDate>
                  </DivOrgDate>
                  {infoStockProduct.isDevolution ? (
                    <></>
                  ) : (
                    <DivOrgInfo>
                      <InfoProduct>Nº Pedido</InfoProduct>
                      <InfoProduct>{infoStockProduct.idStockEntry}</InfoProduct>
                    </DivOrgInfo>
                  )}
                  {infoStockProduct.productBatch ? (
                    <DivOrgInfo>
                      <InfoProduct>Lote</InfoProduct>
                      <InfoBatch>{infoStockProduct.productBatch}</InfoBatch>
                    </DivOrgInfo>
                  ) : (
                    <></>
                  )}
                  {infoStockProduct.isDevolution ? (
                    <DivOrgInfo>
                      <InfoProduct>Devolução</InfoProduct>
                      <InfoProduct>{infoStockProduct.isDevolution}</InfoProduct>
                    </DivOrgInfo>
                  ) : (
                    <></>
                  )}
                </DivCardDetailsProduct>
              );
            })
          )}
        </TableDetailStock>
      </DivTableDetailStock>
    </DivDetailStock>
  );
}
