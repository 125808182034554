import { Person } from "@styled-icons/material";
import React, { useEffect, useState } from "react";
import {
  ButtonUser,
  DivBtnUser,
  DivUserConfig,
  ModalUser,
  SpanConfig,
  SpanLink,
  SpanOut,
} from "./UserConfigStyle";

import { userLogout } from "../../../store/auth/auth.actions";
import { useDispatch, useSelector } from "react-redux";

export default function UserConfig(props) {
  const [showUser, setShowUser] = useState(false);
  const dispatch = useDispatch();

  const infoUser = useSelector((state) => state.auth);

  const logout = async () => {
    await dispatch(userLogout());
  };

  useEffect(() => {
    setShowUser(false);
  }, [props.open]);

  return (
    <DivUserConfig>
      <DivBtnUser>
        <ButtonUser
          onClick={() => {
            setShowUser(!showUser);
          }}
        >
          <Person />
        </ButtonUser>
      </DivBtnUser>

      <ModalUser showUser={showUser}>
        <SpanLink to="/user-account" state={{ idUser: infoUser.idUser }}>
          <SpanConfig>Configurações</SpanConfig>
        </SpanLink>
        <SpanLink to="">
          <SpanOut onClick={logout}>Sair</SpanOut>
        </SpanLink>
      </ModalUser>
    </DivUserConfig>
  );
}
