import React, { useEffect, useState } from "react";
import {
  DivProvider,
  DivScreenProvider,
  TitleProviderReg,
} from "./ProviderRegisterStyle";
import NewProvider from "../../components/Forms/NewProvider/NewProvider";
import SearchProvider from "../../components/Search/SearchProvider/SearchProvider";

import { useDispatch } from "react-redux";
import { allProviders } from "../../store/registers/provider/provider.actions";

export default function ProviderRegister() {
  const dispatch = useDispatch();
  const [providersInfo, setProvidersInfo] = useState([]);
  const [disableFilter, setDisableFilter]= useState(false)

  const getListProviders = async () => {
    const listProviders = await dispatch(allProviders());
    setProvidersInfo(listProviders.payload);
  };
  useEffect(() => {
    getListProviders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (providersInfo.errorStatus === true) {
      alert(providersInfo.message);
      setDisableFilter(true)
    } else {
      setDisableFilter(false)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providersInfo.errorStatus]);
  return (
    <DivProvider>
      <TitleProviderReg>Cadastro Fornecedor</TitleProviderReg>
      <DivScreenProvider>
        <NewProvider getListProviders={getListProviders} />
        <SearchProvider
          providersInfo={providersInfo.provider}
          getListProviders={getListProviders}
          disableFilter={disableFilter}
        />
      </DivScreenProvider>
    </DivProvider>
  );
}
