import React, { useState, useEffect } from "react";
import {
  DivClientRegister,
  TitleClientReg,
  DivScreenClient,
} from "./ClientRegisterStyle";
import NewClient from "../../components/Forms/NewClient/NewClient";
import SearchClient from "../../components/Search/SearchClient/SearchClient";
import { useDispatch } from "react-redux";
import { allClients } from "../../store/registers/clients/clients.actions";

export default function ClienteRegister() {
  const dispatch = useDispatch();
  const [clientsInfo, setClientsInfo] = useState([]);  
  const [disableFilter, setDisableFilter]= useState(false)

  const getListClients = async () => {
    const listClients = await dispatch(allClients());
    setClientsInfo(listClients.payload);
  };

  useEffect(() => {
    getListClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clientsInfo.errorStatus === true) {
      alert(clientsInfo.message);
      setDisableFilter(true)
    } else {
      setDisableFilter(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientsInfo.errorStatus]);

  return (
    <DivClientRegister>
      <TitleClientReg>Cadastro de Cliente</TitleClientReg>
      <DivScreenClient>
        <NewClient getListClients={getListClients} />
        <SearchClient
          clientsInfo={clientsInfo.client}
          getListClients={getListClients}
          disableFilter={disableFilter}
        />
      </DivScreenClient>
    </DivClientRegister>
  );
}
