import React, { useState } from "react";
import {
  DivOrgCardDelete,
  DivOrgBtnClose,
  BtnClose,
  DivOrgLabel,
  DivOrgBtn,
  BtnConfirm,
  BtnCancel,
  LabelDelete,
  DivOrgDelete,
  InfoResult,
  DivOrgResults,
  DivOrgLoading,
} from "./DeleteProductStyle";
import { Close } from "@styled-icons/material";
import { downProduct } from "../../../store/registers/products/products.actions";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";

export default function DeleteProduct(props) {
  const productRemove = props.selectedProduct;
  const dispatch = useDispatch();
  const [deleteProductInfo, setDeleteProductInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  const deleteProduct = async (dataProduct) => {
    setLoading(true);
    const idProduct = dataProduct.idProduct;
    const deleteProduct = await dispatch(downProduct(idProduct));
    setDeleteProductInfo(deleteProduct.payload);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    setTimeout(() => {
      props.setLoadingProducts(true);
      props.setDelProductOption(false);
    }, 4000);
  };

  const parseName = (oneName, secondName) => {
    const firstName = oneName || "";
    const lastName = secondName || "";
    var fullName = "";
    if (lastName.length > 0) {
      fullName = firstName.concat(" ", lastName);
    } else {
      fullName = firstName;
    }
    const formatName = fullName?.split(" ");
    for (var i = 0; i < formatName?.length; i++) {
      formatName[i] =
        formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
    }
    let result = formatName?.join(" ");

    return result;
  };

  return (
    <DivOrgDelete>
      <DivOrgBtnClose>
        <BtnClose onClick={() => props.setDelProductOption(false)}>
          <Close />
        </BtnClose>
      </DivOrgBtnClose>
      <DivOrgCardDelete showDiv={props.delProductOption}>
        <DivOrgLabel>
          <LabelDelete>
            Deseja apagar o produto: {parseName(productRemove?.nameProduct)}
          </LabelDelete>
        </DivOrgLabel>
        <DivOrgBtn>
          <BtnConfirm onClick={() => deleteProduct(productRemove)}>
            Sim
          </BtnConfirm>
          <BtnCancel onClick={() => props.setDelProductOption(false)}>
            Não
          </BtnCancel>
        </DivOrgBtn>
      </DivOrgCardDelete>
      {loading ? (
        <DivOrgLoading>
          <ClipLoader speedMultiplier={3} />
        </DivOrgLoading>
      ) : (
        (deleteProductInfo.errorStatus && (
          <DivOrgResults>
            <InfoResult>{deleteProductInfo.message}</InfoResult>
          </DivOrgResults>
        )) ||
        (deleteProductInfo.successStatus && (
          <DivOrgResults>
            <InfoResult>{deleteProductInfo.message}</InfoResult>
          </DivOrgResults>
        ))
      )}
    </DivOrgDelete>
  );
}
