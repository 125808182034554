import React, { useState } from "react";
import {
  DivOrgCardDelete,
  DivOrgBtnClose,
  BtnClose,
  DivOrgLabel,
  DivOrgBtn,
  BtnConfirm,
  BtnCancel,
  LabelDelete,
  DivOrgDelete,
  InfoResult,
  DivOrgResults,
  DivOrgLoading,
} from "./DeleteBrandStyle";
import { Close } from "@styled-icons/material";
import { downBrand } from "../../../store/registers/brand/brand.actions";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";

export default function DeleteBrand(props) {
  const brandRemove = props.selectedBrand;
  const dispatch = useDispatch();
  const [deleteBrandInfo, setDeleteBrandInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  const deleteBrand = async (dataBrand) => {
    setLoading(true);
    const idBrand = dataBrand.idBrand;
    const deleteBrand = await dispatch(downBrand(idBrand));
    setDeleteBrandInfo(deleteBrand.payload);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    setTimeout(() => {
      props.setLoadingBrands(true)
      props.setDelBrandOption(false)
    }, 4000);
  };

  return (
    <DivOrgDelete>
      <DivOrgBtnClose>
        <BtnClose onClick={() => props.setDelBrandOption(false)}>
          <Close />
        </BtnClose>
      </DivOrgBtnClose>
      <DivOrgCardDelete showDiv={props.delBrandOption}>
        <DivOrgLabel>
          <LabelDelete>
            Deseja apagar a Marca: {brandRemove.brandName} ?
          </LabelDelete>
        </DivOrgLabel>
        <DivOrgBtn>
          <BtnConfirm onClick={() => deleteBrand(brandRemove)}>Sim</BtnConfirm>
          <BtnCancel onClick={() => props.setDelBrandOption(false)}>
            Não
          </BtnCancel>
        </DivOrgBtn>
      </DivOrgCardDelete>
      {loading ? (
        <DivOrgLoading>
          <ClipLoader speedMultiplier={3} />
        </DivOrgLoading>
      ) : (
        (deleteBrandInfo.errorStatus && (
          <DivOrgResults>
            <InfoResult>{deleteBrandInfo.message}</InfoResult>
          </DivOrgResults>
        )) ||
        (deleteBrandInfo.successStatus && (
          <DivOrgResults>
            <InfoResult>{deleteBrandInfo.message}</InfoResult>
          </DivOrgResults>
        ))
      )}
    </DivOrgDelete>
  );
}
