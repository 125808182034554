import React, { useEffect, useRef, useState } from "react";
import {
  BtnClose,
  BtnNewOrder,
  DivOrderAnimation,
  DivOrgBtnClose,
  DivOrgBtnOrder,
  // StatusOrder,
  TitleAnimation,
  // DivOrgLoading,
  BtnPrint,
  DivOrgInfoAnimation,
  DivInfoCompany,
  DivOrgTable,
  DivOrgTitle,
  InfoCompany,
  Line,
  Table,
  TableBody,
  TableFoot,
  TableHead,
  TablePayments,
  TdPayments,
  TdTable,
  TdTableDesc,
  TdTablePrices,
  TdValues,
  ThTable,
  ThTableDesc,
  ThTablePrices,
  TitlePrint,
  TrPayments,
  TrTable,
  DivOrgInfoCompany,
  DivInfoClient,
  // BtnMail,
  PricesFormat,
  DivOrgSelect,
  SelectPaper,
  OptionsPaper,
  DivDelivery,
  TitleDelivery,
  DivOrgDisplay,
  DivOrgBtn,
  InfoDelivery,
  DivOrgTitleDelivery,
} from "./FinisihOrderAnimationStyle";
import { useNavigate } from "react-router-dom";
import { Close } from "@styled-icons/material";
// import { ClipLoader } from "react-spinners";
import { useReactToPrint } from "react-to-print";

export default function FinishOrderAnimation(props) {
  // const orderResponse = props.trueAnimation;
  const infoFinishOrder = props.infoFinishOrder.order;
  const infoDelivery = props.printDelivery;
  const navigate = useNavigate();

  const [orderList, setOrderList] = useState([]);
  const [typePaper, setTypePaper] = useState();

  const printRef = useRef();
  const printOrder = useReactToPrint({
    content: () => printRef.current,

    onAfterPrint: () => {
      navigate("/comercial");
    },
  });

  const parseName = (oneName) => {
    if (oneName) {
      const firstName = oneName || "";

      var fullName = firstName.concat(" ");

      const formatName = fullName.split(" ");
      for (var i = 0; i < formatName.length; i++) {
        formatName[i] =
          formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
      }
      let result = formatName?.join(" ");

      return result;
    }
  }; 

  useEffect(() => {
    if (infoFinishOrder?.itemsCart.length > 0) {
      setOrderList(infoFinishOrder?.itemsCart);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoFinishOrder?.itemsCart]);

  return (
    <DivOrderAnimation
    show={props.showAnimation}
    >
      <DivOrgBtnClose>
        <BtnClose
          onClick={() => {
            props.setShowAnimation(false);
            props.setTrueAnimation(false);
            navigate("/comercial");
          }}
        >
          <Close />
        </BtnClose>
      </DivOrgBtnClose>

      <TitleAnimation>Venda realizada com sucesso</TitleAnimation>
      <DivOrgDisplay>
        <DivOrgInfoAnimation>
          <DivOrgTable ref={printRef} papersType={typePaper}>
            <DivOrgInfoCompany>
              <DivInfoCompany>
                {/* ! - Corrigir essa busca dos dados para um env */}
                <InfoCompany>Nome da Empresa: {process.env.REACT_APP_NAME_EMPRESA}</InfoCompany>
                <InfoCompany>CNPJ: {process.env.REACT_APP_CNPJ_EMPRESA}</InfoCompany>
                <InfoCompany>Endereço: {process.env.REACT_APP_ENDERECO_EMPRESA}</InfoCompany>
                <InfoCompany>Bairro: {process.env.REACT_APP_BAIRRO_EMPRESA}</InfoCompany>
                <InfoCompany>Cidade: {process.env.REACT_APP_CIDADE_EMPRESA} / {process.env.REACT_APP_ESTADO_EMPRESA}</InfoCompany>
              </DivInfoCompany>
              <DivInfoClient>
                <InfoCompany>Cliente: {infoDelivery.nameClient}</InfoCompany>
              </DivInfoClient>
            </DivOrgInfoCompany>
            <DivOrgTitle>
              <TitlePrint>Nota Não fiscal</TitlePrint>
            </DivOrgTitle>
            <Line />
            <Table>
              <TableHead>
                <TrTable>
                  <ThTable>Items</ThTable>
                  <ThTable>Cod.</ThTable>
                  <ThTableDesc>Desc.</ThTableDesc>
                  <ThTable>QTD</ThTable>
                  <ThTablePrices>Preço Unitario</ThTablePrices>
                  <ThTablePrices>Preço Total</ThTablePrices>
                </TrTable>
              </TableHead>

              <TableBody>
                {orderList.map((itemsCart, index) => {
                  return (
                    <TrTable key={index}>
                      <TdTable>{index + 1}</TdTable>
                      <TdTable>{itemsCart.codProd}</TdTable>
                      <TdTableDesc>{itemsCart.nameProduct}</TdTableDesc>
                      <TdTable>{itemsCart.qtd}</TdTable>
                      <TdTablePrices>
                        <PricesFormat
                          placeholder=""
                          displayType="text"
                          value={itemsCart.priceUnit || 0}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale
                          decimalScale={2}
                          prefix={"R$ "}
                        />
                      </TdTablePrices>
                      <TdTablePrices>
                        <PricesFormat
                          placeholder=""
                          displayType="text"
                          value={itemsCart.valueTotalItem || 0}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale
                          decimalScale={2}
                          prefix={"R$ "}
                        />
                      </TdTablePrices>
                    </TrTable>
                  );
                })}
              </TableBody>
            </Table>

            <DivDelivery>
              <DivOrgTitleDelivery>
                <TitleDelivery>Entrega</TitleDelivery>
              </DivOrgTitleDelivery>
              <InfoDelivery>
                Rua: {infoDelivery?.street} N: {infoDelivery?.number}
              </InfoDelivery>
              <InfoDelivery>
                Bairro: {infoDelivery?.district} Cidade: {infoDelivery?.city}/
                {infoDelivery?.state}
              </InfoDelivery>
              <infoDelivery>
                Complemento: {infoDelivery?.complement}
              </infoDelivery>
            </DivDelivery>
            <TablePayments>
              <TableFoot>
                <TrPayments>
                  <TdPayments>Forma de pagamento</TdPayments>
                  <TdValues>{parseName(infoFinishOrder?.formPayment)}</TdValues>
                </TrPayments>
                <TrPayments>
                  <TdPayments>Valor do Frete</TdPayments>
                  <TdValues>
                    <PricesFormat
                      displayType="text"
                      value={infoFinishOrder?.valueDelivery}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale
                      decimalScale={2}
                      prefix={"R$ "}
                    />
                  </TdValues>
                </TrPayments>
                <TrPayments>
                  <TdPayments>Total do pedido</TdPayments>
                  <TdValues>
                    <PricesFormat
                      displayType="text"
                      value={infoFinishOrder?.valueWithDiscount}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale
                      decimalScale={2}
                      prefix={"R$ "}
                    />
                  </TdValues>
                </TrPayments>
                <TrPayments>
                  <TdPayments>Valor Pago</TdPayments>
                  <TdValues>
                    <PricesFormat
                      displayType="text"
                      value={infoFinishOrder?.valueClientPayed}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale
                      decimalScale={2}
                      prefix={"R$ "}
                    />
                  </TdValues>
                </TrPayments>
                <TrPayments>
                  <TdPayments>Troco</TdPayments>
                  <TdValues>
                    <PricesFormat
                      displayType="text"
                      value={infoFinishOrder?.valueChange}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale
                      decimalScale={2}
                      prefix={"R$ "}
                    />
                  </TdValues>
                </TrPayments>
              </TableFoot>
            </TablePayments>
          </DivOrgTable>
        </DivOrgInfoAnimation>

        <DivOrgBtn>
          <DivOrgSelect>
            <SelectPaper
              defaultValue={0}
              onChange={(e) => setTypePaper(e.target.value)}
            >
              <OptionsPaper value={true}>A4</OptionsPaper>
              <OptionsPaper value={false}>Cupom</OptionsPaper>
            </SelectPaper>
          </DivOrgSelect>

          <DivOrgBtnOrder>
            <BtnNewOrder to="/comercial">Novo Pedido</BtnNewOrder>
            <BtnPrint onClick={printOrder}>Imprimir Comprovante</BtnPrint>
            {/* <BtnMail>Enviar por E-mail</BtnMail> */}
          </DivOrgBtnOrder>
        </DivOrgBtn>
      </DivOrgDisplay>
    </DivOrderAnimation>
  );
}
