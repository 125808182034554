import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";
import { Colors } from "../../../variable";

export const DivUserConfig = styled.div`
  margin-right: 15px;
`;
export const DivBtnUser = styled.div`
  width: 40px;
  height: 40px;
`;
export const ButtonUser = styled.button`
  cursor: pointer;
  width: 40px;
  width: 40px;
  border-radius: 100%;
  padding: 5px;
  border: transparent;
  background-color: ${Colors.ButtonsColors.Actions};
`;

export const ModalUser = styled.div`
  display: ${({ showUser }) => (showUser ? "flex" : "none")};
  
  width: 180px;
  height: 105px;
  position: fixed;
  top: 80px;
  right: 5px;
  margin-right: 40px;
  background-color: ${Colors.BackgroundColors.BkComponent};
  border: 1px solid ${Colors.Text.Black};
  border-radius: 25px;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  animation: scale-in-tr 0.3s both;
  @keyframes scale-in-tr {
    0% {
      transform: scale(0);
      transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      transform: scale(1);
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 768px) {
      top: 320px;
      right: 150px;
  }
`;
export const SpanLink = styled(Link)`
  text-decoration: none;
  color: ${Colors.Text.Black};
  font-size: 1rem;
`;
export const SpanConfig = styled.span``;
export const SpanOut = styled.span``;
