import React, { useEffect, useState } from "react";
import { Close, DeleteForever, Edit, Search } from "@styled-icons/material";
import { ClipLoader } from "react-spinners";
import {
  BtnEdit,
  BtnRemove,
  BtnSearch,
  DivBtnEdit,
  DivBtnSearch,
  DivIdDelivery,
  DivDelivery,
  DivDeliveryInfo,
  DivSearchDelivery,
  DivTableSearch,
  DivInfo,
  TitleSearchDelivery,
  NameInput,
  NameLabel,
  SpanCod,
  SpanName,
  DivOrgLoading,
  DivOrgInfo,
  DivOrgCard,
  DivOrgPrices,
  DivOrgCod,
  DivOrgId,
  FormatValueDeliveryText,
  DivFilter,
  DivOrgInputs,
  CodInput,
  BtnCancel,
  CityInput,
  DivOrgFilter,
} from "./SearchDeliveryStyle";
import UpdateDelivery from "../../Update/UpdateDelivery/UpdateDelivery";
import DeleteDelivery from "../../DeleteComponent/DeleteDelivery/DeleteDelivery";

export default function SearchDelivery(props) {
  const deliveryInfo = props.deliverysInfo;

  const [deliveryPopUp, setDeliveryPopUp] = useState(false);
  const [delDeliveryOption, setDelDeliveryOption] = useState(false);
  const [selectedDelivery, setSelectedDelivery] = useState();
  const [filterCodDelivery, setFilterCodDelivery] = useState("");
  const [filterDistrictDelivery, setFilterDistrictDelivery] = useState("");
  const [filterCityDelivery, setFilterCityDelivery] = useState("");
  const [filterInfoDelivery, setFilterInfoDelivery] = useState([]);
  const [showList, setShowList] = useState(false);
  // const [filterValueDelivery, setFilterValueDelivery] = useState("");

  const [loading, setLoading] = useState();
  const [loadingDeliverys, setLoadingDeliverys] = useState(false);
  const [dataDeliveryUpdate, setDataDeliveryUpdate] = useState([]);

  const createListDeliverys = (dataDelivery) => {
    setLoading(true);
    setShowList(true);
    if (showList) {
      setFilterInfoDelivery(dataDelivery);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const parseName = (oneName, secondName) => {
    const firstName = oneName || "";
    const lastName = secondName || "";
    var fullName = "";
    if (lastName.length > 0) {
      fullName = firstName.concat(" ", lastName);
    } else {
      fullName = firstName;
    }
    const formatName = fullName?.split(" ");
    for (var i = 0; i < formatName?.length; i++) {
      formatName[i] =
        formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
    }
    let result = formatName?.join(" ");

    return result;
  };

  const filterDelivery = () => {
    const filterList = deliveryInfo
      .filter((delivery) =>
        filterCodDelivery > 0
          ? delivery.idDelivery === filterCodDelivery
          : delivery
      )
      .filter((delivery) =>
        filterDistrictDelivery.length > 0
          ? parseName(delivery.districtName).includes(
              parseName(filterDistrictDelivery)
            )
          : delivery
      )
      .filter((delivery) =>
        filterCityDelivery.length > 0
          ? parseName(delivery.cityName).includes(parseName(filterCityDelivery))
          : delivery
      );
    setFilterInfoDelivery(filterList);
  };

  useEffect(() => {
    createListDeliverys(deliveryInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryInfo]);

  useEffect(() => {
    setLoading(true);
    if (loadingDeliverys) {
      props.getListDeliverys();
    }
    setTimeout(() => {
      setLoadingDeliverys(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingDeliverys]);

  return (
    <DivSearchDelivery>
      <DivFilter>
        <TitleSearchDelivery>Consulta Delivery</TitleSearchDelivery>
        <DivOrgFilter show={props.disableFilter}>
          <DivOrgInputs>
            <NameLabel>Codigo</NameLabel>
            <CodInput
              value={filterCodDelivery}
              onValueChange={(values, sourceInfo) => {
                setFilterCodDelivery(parseInt(values.value));
              }}
            />
            <NameLabel>Bairro</NameLabel>
            <NameInput
              value={filterDistrictDelivery}
              onChange={(e) => setFilterDistrictDelivery(e.target.value)}
            />
          </DivOrgInputs>
          <DivOrgInputs>
            <NameLabel>Cidade</NameLabel>
            <CityInput
              value={filterCityDelivery}
              onChange={(e) => setFilterCityDelivery(e.target.value)}
            />
            <DivBtnSearch>
              <BtnSearch type="button" onClick={filterDelivery}>
                <Search />
              </BtnSearch>
              <BtnCancel
                type="button"
                onClick={() => {
                  setFilterCityDelivery("");
                  setFilterCodDelivery("");
                  setFilterDistrictDelivery("");
                  setFilterInfoDelivery(deliveryInfo);
                }}
              >
                <Close />
              </BtnCancel>
            </DivBtnSearch>
          </DivOrgInputs>
        </DivOrgFilter>
      </DivFilter>
      <DivTableSearch>
        {loading ? (
          <DivOrgLoading>
            <ClipLoader speedMultiplier={3} color={"#FFF"} />
          </DivOrgLoading>
        ) : (
          filterInfoDelivery.map((deliveryInfo, index) => {
            return (
              <DivDelivery key={index}>
                <DivOrgCard>
                  <DivOrgId>
                    <DivIdDelivery>{index + 1}</DivIdDelivery>
                    {/* <DivIdDelivery>1000</DivIdDelivery> */}
                  </DivOrgId>
                  <DivInfo>
                    <DivDeliveryInfo>
                      <SpanName>
                        Bairro: {parseName(deliveryInfo.districtName)}
                      </SpanName>
                      <SpanName>
                        Cidade: {parseName(deliveryInfo.cityName)}
                      </SpanName>

                      <DivOrgInfo>
                        <DivOrgCod>
                          <SpanCod>Codigo: {deliveryInfo.idDelivery}</SpanCod>
                        </DivOrgCod>
                        <DivOrgPrices>
                          <SpanCod>Valor do frete</SpanCod>
                          <FormatValueDeliveryText
                            displayType="text"
                            value={deliveryInfo.valueDelivery}
                            decimalSeparator=","
                            thousandSeparator="."
                            fixedDecimalScale
                            decimalScale={2}
                            prefix={"R$ "}
                          />
                        </DivOrgPrices>
                      </DivOrgInfo>
                    </DivDeliveryInfo>
                  </DivInfo>
                  <DivBtnEdit>
                    <BtnEdit
                      onClick={() => {
                        setDeliveryPopUp(!deliveryPopUp);
                        setDataDeliveryUpdate(deliveryInfo);
                        setSelectedDelivery(deliveryInfo);
                      }}
                    >
                      <Edit />
                    </BtnEdit>
                    {deliveryPopUp &&
                      deliveryInfo.idDelivery ===
                        selectedDelivery.idDelivery && (
                        <UpdateDelivery
                          setLoadingDeliverys={setLoadingDeliverys}
                          dataDeliveryUpdate={dataDeliveryUpdate}
                          DeliveryPopUp={deliveryPopUp}
                          setDeliveryPopUp={setDeliveryPopUp}
                        />
                      )}
                    <BtnRemove
                      onClick={() => {
                        setDelDeliveryOption(!delDeliveryOption);
                        setSelectedDelivery(deliveryInfo);
                      }}
                    >
                      <DeleteForever />
                    </BtnRemove>
                  </DivBtnEdit>
                </DivOrgCard>
                {delDeliveryOption &&
                  deliveryInfo.idDelivery === selectedDelivery.idDelivery && (
                    <DeleteDelivery
                      setLoadingDeliverys={setLoadingDeliverys}
                      selectedDelivery={selectedDelivery}
                      delDeliveryOption={delDeliveryOption}
                      setDelDeliveryOption={setDelDeliveryOption}
                    />
                  )}
              </DivDelivery>
            );
          })
        )}
      </DivTableSearch>
    </DivSearchDelivery>
  );
}
