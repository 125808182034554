import React, { useState } from "react";
import {
  DivOrgCardDelete,
  DivOrgBtnClose,
  BtnClose,
  DivOrgLabel,
  DivOrgBtn,
  BtnConfirm,
  BtnCancel,
  LabelDelete,
  DivOrgDelete,
  InfoResult,
  DivOrgResults,
  DivOrgLoading,
} from "./DeleteClientStyle";
import { Close } from "@styled-icons/material";
import { downClient } from "../../../store/registers/clients/clients.actions";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";

export default function DeleteClient(props) {
  const clientRemove = props.selectedClient;
  const dispatch = useDispatch();
  const [deleteClientInfo, setDeleteClientInfo] = useState([]);
  const [loading, setLoading] = useState();

  const deleteClient = async (dataClient) => {
    setLoading(true);
    const idClient = dataClient.idClient;
    const deleteClient = await dispatch(downClient(idClient));
    setDeleteClientInfo(deleteClient.payload);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    setTimeout(() => {
      props.setLoadingClients(true);
      props.setDelClientOption(false)
    }, 4000);
  };

  const parseName = (oneName, secondName) => {
    const firstName = oneName || "";
    const lastName = secondName || "";
    var fullName = "";
    if (lastName.length > 0) {
      fullName = firstName.concat(" ", lastName);
    } 
    else {
      fullName = firstName.concat("", lastName);
    }
    const formatName = fullName.split(" ");
    for (var i = 0; i < formatName.length; i++) {
      formatName[i] =
        formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
    }
    let result = formatName.join(" ");

    return result;
  };

  return (
    <DivOrgDelete>
      <DivOrgBtnClose>
        <BtnClose onClick={() => props.setDelClientOption(false)}>
          <Close />
        </BtnClose>
      </DivOrgBtnClose>
      <DivOrgCardDelete showDiv={props.delClientOption}>
        <DivOrgLabel>
          <LabelDelete>
            Deseja apagar o cliente:{" "}
            {parseName(clientRemove.clientName, clientRemove.lastName)} ?
          </LabelDelete>
        </DivOrgLabel>
        <DivOrgBtn>
          <BtnConfirm onClick={() => deleteClient(clientRemove)}>
            Sim
          </BtnConfirm>
          <BtnCancel onClick={() => props.setDelClientOption(false)}>
            Não
          </BtnCancel>
        </DivOrgBtn>
      </DivOrgCardDelete>
      {loading ? (
        <DivOrgLoading>
          <ClipLoader speedMultiplier={3} />
        </DivOrgLoading>
      ) : (
        (deleteClientInfo.errorStatus && (
          <DivOrgResults>
            <InfoResult>{deleteClientInfo.message}</InfoResult>
          </DivOrgResults>
        )) ||
        (deleteClientInfo.successStatus && (
          <DivOrgResults>
            <InfoResult>{deleteClientInfo.message}</InfoResult>
          </DivOrgResults>
        ))
      )}
    </DivOrgDelete>
  );
}
