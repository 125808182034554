import React, { useEffect, useState } from "react";
import {
  DivTableStock,
  DivTableInput,
  DivCardItem,
  DivItem,
  NameItem,
  BrandItem,
  QtdItem,
  DivBtn,
  DivFilter,
  LinkPage,
  PriceTotalStock,
  DivItemValues,
  IdItem,
  DivId,
  InfoItem,
  DivItemQtd,
  BtnStock,
  DivOrgCard,
  DivOrgInfo,
  CodItem,
  BtnSearch,
  DivOrgInput,
  BtnCancel,
  DivOrgBtn,
  BrandInput,
  NameInput,
  CodInput,
  DivOrgInputsFilter,
  InfoLabel,
} from "./TableStockStyle";
import ButtonAdd from "../../Button/ButtonAdd";
import { Close, History, More, Search } from "@styled-icons/material";
import DetailStock from "../../Details/DetailStock/DetailStock";

export default function TableStock(props) {
  const listProductStock = props.stockNow;
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [stockPopUp, setStockPopUp] = useState(false);
  const [showList, setShowList] = useState(false);

  const [filterCodStock, setFilterCodStock] = useState();
  const [filterProductStock, setFilterProductStock] = useState("");
  const [filterBrandStock, setFilterBrandStock] = useState("");
  const [filterInfoStock, setFilterInfoStock] = useState([]);

  const createListStock = (dataStock) => {
    setShowList(true);

    if (showList) {
      setFilterInfoStock(dataStock);
    }
  };

  const parseName = (oneName, secondName) => {
    const firstName = oneName || "";
    const lastName = secondName || "";
    var fullName = "";
    if (lastName.length > 0) {
      fullName = firstName.concat(" ", lastName);
    } else {
      fullName = firstName;
    }
    const formatName = fullName?.split(" ");
    for (var i = 0; i < formatName?.length; i++) {
      formatName[i] =
        formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
    }
    let result = formatName?.join(" ");

    return result;
  };

  const filterStock = () => {
    const filterList = listProductStock
      .filter((infoStock) =>
        filterProductStock.length > 0
          ? parseName(infoStock.nameProduct).includes(
              parseName(filterProductStock)
            )
          : infoStock
      )
      .filter((infoStock) =>
        filterBrandStock.length > 0
          ? parseName(infoStock.brandName).includes(parseName(filterBrandStock))
          : infoStock
      )
      .filter((infoStock) =>
        filterCodStock > 0
          ? infoStock.codProd.includes(filterCodStock)
          : infoStock
      );

      setFilterInfoStock(filterList)
  };

  useEffect(() => {
    createListStock(listProductStock);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listProductStock]);

  return (
    <>
      <DivFilter>
        <ButtonAdd />
        <DivOrgInput show={props.disableFilter}>
          <DivOrgInputsFilter>
            <InfoLabel>Nome do Produto</InfoLabel>
            <NameInput
              value={filterProductStock}
              onChange={(e) => setFilterProductStock(e.target.value)}
            />
          </DivOrgInputsFilter>
          <DivOrgInputsFilter>
            <InfoLabel>Marca</InfoLabel>
            <BrandInput
              value={filterBrandStock}
              onChange={(e) => setFilterBrandStock(e.target.value)}
            />
          </DivOrgInputsFilter>
          <DivOrgInputsFilter>
            <InfoLabel>Codigo do Produto</InfoLabel>
            <CodInput
              value={filterCodStock}
              onValueChange={(values, sourceInfo) => {
                setFilterCodStock(values.value);
              }}
            />
          </DivOrgInputsFilter>
          <DivOrgBtn>
            <BtnSearch type="button" onClick={filterStock}>
              <Search />
            </BtnSearch>
            <BtnCancel
              type="button"
              onClick={() => {
                setFilterBrandStock("");
                setFilterProductStock("");
                setFilterCodStock("");
                setFilterInfoStock(listProductStock);
              }}
            >
              <Close />
            </BtnCancel>
          </DivOrgBtn>
        </DivOrgInput>
      </DivFilter>
      <DivTableStock>
        <DivTableInput>
          {filterInfoStock.map((productStock, id) => {
            return (
              <DivCardItem key={id}>
                <DivId>
                  <IdItem>{id + 1}</IdItem>
                </DivId>
                <DivOrgCard>
                  <DivItem>
                    <NameItem>{parseName(productStock.nameProduct)}</NameItem>
                    <DivOrgInfo>
                      <CodItem>Código: {productStock.codProd}</CodItem>
                      <BrandItem>
                        Marca: {parseName(productStock.brandName)}
                      </BrandItem>
                    </DivOrgInfo>
                  </DivItem>
                  <DivOrgInfo>
                    <DivItemQtd>
                      <QtdItem>QTD Total</QtdItem>
                      <QtdItem> {productStock.totalQtd}</QtdItem>
                    </DivItemQtd>
                    <DivItemValues>
                      <InfoItem>Total em estoque</InfoItem>
                      <PriceTotalStock
                        customInput={InfoItem}
                        displayType="text"
                        value={productStock.priceTotal || 0}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale
                        decimalScale={2}
                        prefix={"R$ "}
                      />
                    </DivItemValues>
                  </DivOrgInfo>
                </DivOrgCard>

                <DivBtn>
                  <LinkPage
                    to="historic-stock"
                    state={{ productStock: productStock }}
                  >
                    <History />
                  </LinkPage>
                  <BtnStock
                    onClick={() => {
                      setSelectedProduct(productStock);
                      setStockPopUp(!stockPopUp);
                    }}
                  >
                    <More />
                  </BtnStock>
                </DivBtn>
                {stockPopUp &&
                  productStock.idProduct === selectedProduct.idProduct && (
                    <DetailStock
                      selectedProduct={selectedProduct}
                      stockPopUp={stockPopUp}
                      setStockPopUp={setStockPopUp}
                    />
                  )}
              </DivCardItem>
            );
          })}
        </DivTableInput>
      </DivTableStock>
    </>
  );
}
