import { createAsyncThunk } from "@reduxjs/toolkit";
import { resumeExpensePayed } from "../../../api/routes/info/expense/infoExpense";

export const infoExpensePayed = createAsyncThunk(
    "api/resume-expense-payed",
    async (infoMonth, {rejectWithValue}) => {
        const response = await resumeExpensePayed(infoMonth)
        if(response.errorStatus === true) {
            return rejectWithValue(response)
        }
        return response
    }
)