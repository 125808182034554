import React, { useState } from "react";
import {
  DivOrgCardDelete,
  DivOrgBtnClose,
  BtnClose,
  DivOrgLabel,
  DivOrgBtn,
  BtnConfirm,
  BtnCancel,
  LabelDelete,
  DivOrgDelete,
  InfoResult,
  DivOrgResults,
  DivOrgLoading,
} from "./DeleteUserStyle";
import { Close } from "@styled-icons/material";
import { downUser } from "../../../store/registers/users/user.actions";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";

export default function DeleteUser(props) {
  const userRemove = props.selectedUser;
  const dispatch = useDispatch();
  const [deleteUserInfo, setDeleteUserInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  const deleteUser = async (dataUser) => {
    setLoading(true);
    const idUser = dataUser.idUser;
    const deleteUser = await dispatch(downUser(idUser));
    
    setDeleteUserInfo(deleteUser.payload);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    setTimeout(() => {
      props.setLoadingUsers(true);
      props.setDelUserOption(false)
    }, 5000);
  };

  return (
    <DivOrgDelete>
      <DivOrgBtnClose>
        <BtnClose onClick={() => props.setDelUserOption(false)}>
          <Close />
        </BtnClose>
      </DivOrgBtnClose>
      <DivOrgCardDelete showDiv={props.delUserOption}>
        <DivOrgLabel>
          <LabelDelete>
            Deseja apagar o usuário: {userRemove.username}
          </LabelDelete>
        </DivOrgLabel>
        <DivOrgBtn>
          <BtnConfirm onClick={() => deleteUser(userRemove)}>Sim</BtnConfirm>
          <BtnCancel onClick={() => props.setDelUserOption(false)}>
            Não
          </BtnCancel>
        </DivOrgBtn>
      </DivOrgCardDelete>
      {loading ? (
        <DivOrgLoading>
          <ClipLoader speedMultiplier={3} />
        </DivOrgLoading>
      ) : (
        (deleteUserInfo.errorStatus && (
          <DivOrgResults>
            <InfoResult>{deleteUserInfo.message}</InfoResult>
          </DivOrgResults>
        )) ||
        (deleteUserInfo.successStatus && (
          <DivOrgResults>
            <InfoResult>{deleteUserInfo.message}</InfoResult>
          </DivOrgResults>
        ))
      )}
    </DivOrgDelete>
  );
}
