import styled from "styled-components";
import { NumericFormat as Numeric } from "react-number-format";
import { Colors, Phone_media, Tablet_media } from "../../../variable";

export const DivDetailStock = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  position: absolute;
  top: 145px;
  left: 150px;
  right: 170px;
  width: 80%;
  height: 80%;
  background-color: ${Colors.BackgroundColors.BKBlur};
  backdrop-filter: blur(10px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid black;
  border-radius: 25px;

  animation: scale-in-tr 0.1s both;
  @keyframes scale-in-tr {
    0% {
      transform: scale(0);
      transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      transform: scale(1);
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }

  ${Phone_media.Phone_Pop_UP}
  ${Tablet_media.Tablet_Pop_Up}
`;
export const DivBtnClose = styled.div`
  width: 70%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const BtnClose = styled.button`
  width: 4%;

  background: none;
  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: translateY(4px);
  }
`;

export const DivOrgTitle = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const TitleDetailStock = styled.h4``;
export const DivCardDetailsProduct = styled.div`
  width: 100%;
  height: 15%;
  margin: 5px auto;
  padding: 5px;
  background: ${Colors.BackgroundColors.BkCards.White};
  border-radius: 25px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    width: 100%;
    height: 20%;
  }
`;

export const DivOrgInfo = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const DivOrgDate = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
`;

export const InfoProduct = styled.p`
  font-size: 0.9em;
`;
export const InfoQtd = styled.p`
  font-size: 0.85em;
`;
export const InfoBatch = styled.p`
  font-size: 0.85em;
`;
export const InfoPrice = styled(Numeric)`
  width: 80%;
  font-size: 0.8em;
  display: flex;
  justify-content:center;
`;
// export const InfoProduct = styled(Numeric)``
export const InfoDate = styled.p`
  font-size: 0.8em;
`;
export const DivTableDetailStock = styled.div`
  width: 70%;
  height: 80%;
  margin: 10px;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  border:1px solid black;
  background: ${Colors.BackgroundColors.BkComponent};
  border-radius: 25px;
  ${Phone_media.Phone_table};
  ${Tablet_media.Tablet_table};
`;
export const TableDetailStock = styled.div`
  width: 100%;
  height: 100%;
  margin: 5px auto;
  padding: 10px;
  background: ${Colors.BackgroundColors.BkTable};
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;

  overflow: auto;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px ${Colors.ButtonsColors.ShadowButton};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px ${Colors.BackgroundColors.BkComponent};
  }
`;

export const DivOrgLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;