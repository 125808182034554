import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  orderCheckout
} from "../../api/routes/commercial/finishOrder";


export const checkoutOrder = createAsyncThunk(
  "cart/checkout",
  async (infoOrder, {rejectWithValue}) => {
    const response = await orderCheckout(infoOrder)
    if(response.errorStatus === true) {
      return rejectWithValue(response)
    }
    return response
  }
);