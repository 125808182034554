import React from "react";
import { BtnAdd, LinkPage } from "./ButtonStyle";
import { Add } from "@styled-icons/material";

export default function ButtonAdd() {
  return (
    <BtnAdd>
      <LinkPage to="stock-item">
        <Add />
      </LinkPage>
    </BtnAdd>
  );
}
