import React from "react";
import {
  DivComercial,
  DivOrgComercial,
  TitleComercial,
} from "./CommercialStyle";

import Order from "../../components/Forms/Order/Order";
import { useLocation } from "react-router-dom";

export default function Commercial() {
  const location = useLocation();
  let { orderChange } = 0;
  orderChange = location.state || {};
  // ! Por em quanto não achei uma solução melhor para limpar a lista quando for alterar - 05/07/2023
  window.history.replaceState(orderChange, null);
  const infoOrderChange = orderChange;

  // Fazer que a chamada inicie aqui e a order envie para ca
  return (
    <DivComercial>
      <TitleComercial>Comercial</TitleComercial>
      <DivOrgComercial>
        <Order infoOrderChange={infoOrderChange.orderChange} />
      </DivOrgComercial>
    </DivComercial>
  );
}
