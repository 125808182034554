import React from "react";
import {
  DivOrgProduct,
  DivInfoProduct,
  InfoProductResult,
  DivOrgInfo,
  DivBtnClose,
  BtnClose,
  DivOrgPrices,
} from "./InfoProductStyle";
import { Close } from "@styled-icons/material";
import { NumericFormat } from "react-number-format";

export default function InfoProduct(props) {
  const productInfo = props.selectedProductView;
  return (
    <DivOrgProduct show={props.productView}>
      <DivInfoProduct>
        <DivOrgPrices>
          <DivOrgInfo>
            <InfoProductResult>
              Preço de Compra:
              <NumericFormat
                customInput={InfoProductResult}
                displayType="text"
                value={productInfo.priceBuy}
                decimalSeparator=","
                thousandSeparator="."
                decimalScale={2}
                prefix={"R$ "}
              />
            </InfoProductResult>
          </DivOrgInfo>
          <DivOrgInfo>
            <InfoProductResult>
              Preço de venda:
              <NumericFormat
                customInput={InfoProductResult}
                displayType="text"
                value={productInfo.priceSell}
                decimalSeparator=","
                thousandSeparator="."
                decimalScale={2}
                prefix={"R$ "}
              />
            </InfoProductResult>
          </DivOrgInfo>
          <DivOrgInfo>
            <InfoProductResult>
              Porcentagem de Lucro: {productInfo.percentSell}%
            </InfoProductResult>
          </DivOrgInfo>
          <DivOrgInfo>
            <InfoProductResult>
              Valor do Lucro
              <NumericFormat
                customInput={InfoProductResult}
                displayType="text"
                value={"3,50"}
                decimalSeparator=","
                thousandSeparator="."
                decimalScale={2}
                prefix={"R$ "}
              />
            </InfoProductResult>
          </DivOrgInfo>
        </DivOrgPrices>
        <DivOrgPrices>
          <DivOrgInfo>
            <InfoProductResult>Estoque Minimo: {productInfo.minStock}</InfoProductResult>
          </DivOrgInfo>
          <DivOrgInfo>
            <InfoProductResult>Estoque Maximo: {productInfo.maxStock}</InfoProductResult>
          </DivOrgInfo>
          <DivOrgInfo>
            <InfoProductResult>Marca: {productInfo.nameBrand}</InfoProductResult>
          </DivOrgInfo>
        </DivOrgPrices>
      </DivInfoProduct>
      <DivBtnClose>
        <BtnClose onClick={() => props.setProductView(false)}>
          <Close />
        </BtnClose>
      </DivBtnClose>
    </DivOrgProduct>
  );
}
