import React, { useEffect, useState } from "react";
import {
  DivProductRegister,
  DivScreenProduct,
  TitleProduct,
} from "./ProductRegisterStyle";
import NewProduct from "../../components/Forms/NewProduct/NewProduct";
import SearchProduct from "../../components/Search/SearchProduct/SearchProduct";

import { useDispatch } from "react-redux";
import { allProducts } from "../../store/registers/products/products.actions";

export default function ProductRegister() {
  const dispatch = useDispatch();
  const [productsInfo, setProductsInfo] = useState([]);
  const [disableFilter, setDisableFilter]= useState(false)

  const getListProducts = async () => {
    const listProducts = await dispatch(allProducts());
    setProductsInfo(listProducts.payload);
  };

  useEffect(() => {
    getListProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (productsInfo.errorStatus === true) {
      alert(productsInfo.message);
      setDisableFilter(true)
    } else {
      setDisableFilter(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsInfo.errorStatus]);

  return (
    <DivProductRegister>
      <TitleProduct>Cadastro de Produto</TitleProduct>
      <DivScreenProduct>
        <NewProduct getListProducts={getListProducts} />
        <SearchProduct
          productsInfo={productsInfo.product}
          getListProducts={getListProducts}
          disableFilter={disableFilter}
        />
      </DivScreenProduct>
    </DivProductRegister>
  );
}
