import styled from 'styled-components';
import { Colors, Phone_media, Tablet_media } from '../../variable';

export const DivFinishOrder = styled.div`
  width: 1100px;
  margin: auto;

  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;

  background-color: ${Colors.BackgroundColors.BkDiv};
  border-radius: 25px;
  padding: 15px;
  ${Phone_media.Phone_column}
  ${Tablet_media.Tablet_column}
`;