import React, { useEffect, useState } from "react";
import {
  DivDelivery,
  TitleDelivery,
  DivScreenDelivery,
} from "./DeliveryRegisterStyle";
import NewDelivery from "../../components/Forms/NewDelivery/NewDelivery";
import SearchDelivery from "../../components/Search/SearchDelivery/SearchDelivery";

import { useDispatch } from "react-redux";
import { getAllDelivery } from "../../store/registers/delivery/delivery.actions";

export default function DeliveryRegister() {
  const dispatch = useDispatch();
  const [deliverysInfo, setDeliverysInfo] = useState([]);
  const [disableFilter, setDisableFilter]= useState(false)

  const getListDeliverys = async () => {
    const listDelivery = await dispatch(getAllDelivery());
    
    setDeliverysInfo(listDelivery.payload);
  };

  useEffect(() => {
    getListDeliverys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (deliverysInfo.errorStatus === true) {
      alert(deliverysInfo.message);
      setDisableFilter(true)
    } else {
      setDisableFilter(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliverysInfo.errorStatus]);
  return (
    <DivDelivery>
      <TitleDelivery>Preço do Delivery</TitleDelivery>
      <DivScreenDelivery>
        <NewDelivery 
          getListDeliverys={getListDeliverys}
        />
        <SearchDelivery
          deliverysInfo={deliverysInfo.allDeliverys}
          getListDeliverys={getListDeliverys}
          disableFilter={disableFilter}
        />
      </DivScreenDelivery>
    </DivDelivery>
  );
}
