import React, { useEffect, useState } from "react";
import { infoState } from "../../utils/infoState.mjs";
import {
  DivCloseOrder,
  DivLocal,
  FormFinal,
  DivTotal,
  DivOrder,
  TitleOrder,
  PriceOrder,
  Line,
  DivOrgOrder,
  DivOptions,
  DivOrgSelect,
  SelectOptionsToPay,
  SelectDescont,
  ChangeInput,
  DivInputChange,
  ChangeLabel,
  BtnFinish,
  OptionsPay,
  TitleLocal,
  DivBtnDelivery,
  BtnDelivery,
  BtnGetOrder,
  DivOrgClientInfo,
  DivOrgStreet,
  InfoStreet,
  DivOrgDistrict,
  InfoDistrict,
  DivOrgComplement,
  InfoComplement,
  DivOrgNotDelivery,
  InfoNotDelivery,
  ValuePay,
  DivOrgValuePay,
  DivOrgChangeValue,
  DivFormDelivery,
  DivOrgLocal,
  ButtonNewLocal,
  DivOrgInputs,
  NewStreet,
  NewLocalNumber,
  NewComplement,
  NewDistrict,
  NewCity,
  DivOrgNewStreet,
  DivOrgNewComplement,
  DivOrgNewDistrict,
  SelectOptionNewState,
  Options,
  BtnAddLocal,
  CloseNewLocal,
  DivBtnLocal,
  DivOrgBtnNewLocal,
  DivOrgBtnClose,
  DivOrgResults,
  InfoResult,
  DivOrgLoading,
  TitleNewDelivery,
  ValueDelivery,
  LoadingInfo,
  TitleCloseOrder,
} from "./CloseOrderStyle";

import { useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { checkoutOrder } from "../../store/commercial/finishOrder.actions";
import { useDispatch } from "react-redux";
import { Close } from "@styled-icons/material";
import FinishOrderAnimation from "../Animations/FinishOrderAnimation";
import {
  createDelivery,
  getNameDelivery,
} from "../../store/registers/delivery/delivery.actions";
import { ClipLoader } from "react-spinners";

export default function CloseOrder(props) {
  const dispatch = useDispatch();
  const [infoClientLocal, setInfoClientLocal] = useState(
    props.infoOrder.infoClient
  );

  const [showChange, setShowChange] = useState("");
  const [showDescont, setShowDescont] = useState("");
  const [showDelivery, setShowDelivery] = useState(true);
  const [showFormDelivery, setShowFormDelivery] = useState(false);
  const [showDiv, setShowDiv] = useState(false);

  const [infoDeliveryValue, setInfoDeliveryValue] = useState();
  const [loadingDelivery, setLoadingDelivery] = useState(false);
  const [valueDelivery, setValueDelivery] = useState();
  const [valueToDelivery, setValueToDelivery] = useState(0);

  const [clientPayed, setClientPayed] = useState(0);
  const [printDelivery, setPrintDelivery] = useState([])

  const [infoFinishOrder, setInfoFinishOrder] = useState({});
  const [closeBtn, setCloseBtn] = useState(false);
  const [trueAnimation, setTrueAnimation] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);

  const [registerLocal, setRegisterLocal] = useState({
    nameClient: infoClientLocal?.nameClient || "Cliente Não identificado",
    idClient: infoClientLocal?.idClient || 0,
    street: infoClientLocal?.street || "",
    localNumber: infoClientLocal?.localNumber || "",
    complement: infoClientLocal?.complement || "",
    district: infoClientLocal?.district || "",
    city: infoClientLocal?.city || "",
    state: infoClientLocal?.state || "",
  });

  const [percentDiscount, setPercentDiscount] = useState({});
  const [valuePayed, setValuePayed] = useState({});
  const { register, handleSubmit, watch, setValue, reset } = useForm({
    defaultValues: {
      valueDiscount: 0,
      percentDiscount: 0,
      valueDelivery: 0,
      valueClientPayed: 0,
      valueWithDiscount: 0,
      valueNoDiscount: 0,
      valueChange: 0,
    },
  });

  const infoProducts = props.listOrder;
  const infoOrder = props.infoOrder;

  const handleChangeValue = (event) => {
    const getSelected = event.target.value;
    setShowChange(getSelected);
  };
  const handleChangeDescont = (event) => {
    const getDescont = event.target.value;
    setShowDescont(getDescont);
  };
  const handleChangeDelivery = () => {
    setShowDelivery(true);
    getValueDelivery(registerLocal?.district, registerLocal?.city);
  };
  const handleChangeNotDelivery = () => {
    setShowDelivery(false);
    setValueToDelivery(0);
  };

  const checkLocal = () => {
    if (Object.keys(infoClientLocal).length === 1) {
      setShowFormDelivery(!showFormDelivery);
    }
  };

  const registerNewLocal = () => {
    setInfoClientLocal(registerLocal);
  };

  const getValueDelivery = async (nameDistrict, cityName) => {
    const infoDistrict = {
      nameDistrict: nameDistrict.toLowerCase(),
      cityName: cityName.toLowerCase(),
    };
    if (
      infoDistrict.nameDistrict.length > 0 &&
      infoDistrict.cityName.length > 0
    ) {
      setLoadingDelivery(true);
      const infoDelivery = await dispatch(getNameDelivery(infoDistrict));


      setInfoDeliveryValue(infoDelivery.payload);
      setValueToDelivery(
        infoDelivery.payload.districtDelivery?.valueDelivery || 0
      );
      setTimeout(() => {
        setLoadingDelivery(false);
      }, 1000);
    }
  };

  const createNewDelivery = async () => {
    const dataDelivery = {
      districtName: registerLocal.district,
      cityName: registerLocal.city,
      valueDelivery: valueDelivery,
    };

    if (dataDelivery.valueDelivery > 0) {
      const newDelivery = await dispatch(createDelivery(dataDelivery));
      setInfoDeliveryValue(newDelivery.payload);

      setShowDiv(!showDiv);
      setCloseBtn(!closeBtn);
      handleChangeDelivery()
    } else {
      window.alert("Adicione um valor para a entrega");
    }
  };

  // ! -Receber os valores do pedido */

  let discount = Number(percentDiscount);
  let priceOrder = infoOrder.valueOrder + valueToDelivery;

  useEffect(() => {
    setClientPayed(Number(valuePayed));
  }, [valuePayed]);

  const finishOrder = async (dataOrder) => {
    if (registerLocal.length > 0) {
      dataOrder.infoClient = registerLocal;
      setPrintDelivery(registerLocal)
    } else {
      dataOrder.infoClient = registerLocal;
      setPrintDelivery(registerLocal)
    }
    if (valueToDelivery > 0) {
      dataOrder.valueDelivery = valueToDelivery;
    } else {
      dataOrder.valueDelivery = 0;
    }
    dataOrder.itensOrder = infoProducts;

    dataOrder.idOrder = infoOrder.numberOrder;
    dataOrder.valueClientPayed = clientPayed;

    const newOrder = await dispatch(checkoutOrder(dataOrder));
    setInfoFinishOrder(newOrder.payload);
  };

  useEffect(() => {
    if (infoFinishOrder.codeStatus === 200) {
      // ! - Chama a animação de finalizar o pedido
      setShowAnimation(!showAnimation);
      setTrueAnimation(!trueAnimation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoFinishOrder.codeStatus]);

  useEffect(() => {
    let totalOrder;
    if (discount > 0) {
      const valueDiscount = parseFloat((priceOrder * discount) / 100).toFixed(
        2
      );

      totalOrder =
        priceOrder - parseFloat((priceOrder * discount) / 100 || "0");

      setValue("valueWithDiscount", Number(totalOrder.toFixed(2)));
      setValue("valueDiscount", Number(valueDiscount));
      setValue("valueNoDiscount", Number(priceOrder.toFixed(2)));
    } else {
      totalOrder = priceOrder;
      setValue("valueWithDiscount", Number(totalOrder.toFixed(2)));
      setValue("valueNoDiscount", Number(priceOrder.toFixed(2)));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discount, priceOrder]);

  let moneySelect = showChange;

  useEffect(() => {
    let totalChange;
    if (moneySelect === "dinheiro" && clientPayed > 0 && valuePayed > 0) {
      let valueWithDiscount = watch("valueWithDiscount");
      totalChange = clientPayed - valueWithDiscount;

      setValue("valueChange", Number(totalChange.toFixed(2)));
      setValue("valueNoDiscount", Number(priceOrder.toFixed(2)));
    } else {
      setValue("valueChange", 0);
      setClientPayed(Number(priceOrder.toFixed(2)));
      setValue("valueNoDiscount", Number(priceOrder.toFixed(2)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moneySelect, clientPayed]);

  useEffect(() => {
    if (showDescont.length === 0 || showDescont === "nao") {
      setValue("percentDiscount", 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDescont, setValue, clientPayed]);

  useEffect(() => {
    checkLocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (registerLocal?.district?.length > 0) {
      getValueDelivery(registerLocal?.district, registerLocal?.city);
    } else {
      setValueDelivery(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerLocal.district, registerLocal.city]);

  return (
    <DivCloseOrder>
      <TitleCloseOrder>Finalizar Pedido</TitleCloseOrder>
      <FormFinal onSubmit={handleSubmit(finishOrder)}>
        <DivLocal>
          <TitleLocal>Endereço</TitleLocal>
          <DivBtnDelivery>
            <BtnDelivery type="button" onClick={() => handleChangeDelivery()}>
              Entregar
            </BtnDelivery>
            <BtnGetOrder
              type="button"
              onClick={() => handleChangeNotDelivery()}
            >
              Retirar
            </BtnGetOrder>
          </DivBtnDelivery>
          {showDelivery ? (
            <DivOrgClientInfo>
              <DivOrgLocal showDiv={showDiv}>
                <DivOrgStreet>
                  <InfoStreet>Rua: {registerLocal.street}</InfoStreet>
                  <InfoStreet> Nº {registerLocal.localNumber}</InfoStreet>
                </DivOrgStreet>
                <DivOrgComplement>
                  <InfoComplement>
                    Complemento: {registerLocal.complement}
                  </InfoComplement>
                </DivOrgComplement>
                <DivOrgDistrict>
                  <InfoDistrict>Bairro: {registerLocal.district}</InfoDistrict>
                  <InfoDistrict>
                    Cidade: {registerLocal.city}/{registerLocal.state}
                  </InfoDistrict>
                </DivOrgDistrict>
              </DivOrgLocal>
              <DivFormDelivery showDiv={showDiv}>
                <DivBtnLocal showDiv={showDiv}>
                  <DivOrgBtnNewLocal>
                    <ButtonNewLocal
                      closeBtn={closeBtn}
                      onClick={() => {
                        setShowDiv(!showDiv);
                        setCloseBtn(!closeBtn);
                      }}
                    >
                      Alterar Endereço
                    </ButtonNewLocal>
                  </DivOrgBtnNewLocal>
                  <DivOrgBtnClose>
                    <CloseNewLocal
                      showDiv={showDiv}
                      onClick={() => {
                        setShowDiv(!showDiv);
                        setCloseBtn(!closeBtn);
                        reset();
                      }}
                    >
                      <Close />
                    </CloseNewLocal>
                  </DivOrgBtnClose>
                </DivBtnLocal>
                <DivOrgInputs showDiv={showDiv}>
                  <TitleNewDelivery>Adicionar Bairro</TitleNewDelivery>
                  <DivOrgNewStreet>
                    <NewStreet
                      placeholder="Rua"
                      value={registerLocal.street}
                      onChange={(e) =>
                        setRegisterLocal({
                          ...registerLocal,
                          street: e.target.value,
                        })
                      }
                    />
                    <NewLocalNumber
                      placeholder="Nº"
                      value={registerLocal.localNumber}
                      onChange={(e) =>
                        setRegisterLocal({
                          ...registerLocal,
                          localNumber: e.target.value,
                        })
                      }
                    />
                  </DivOrgNewStreet>
                  <DivOrgNewComplement>
                    <NewComplement
                      placeholder="Complemento"
                      value={registerLocal.complement}
                      onChange={(e) =>
                        setRegisterLocal({
                          ...registerLocal,
                          complement: e.target.value,
                        })
                      }
                    />
                    {infoDeliveryValue?.codeStatus === 404 && (
                      <ValueDelivery
                        placeholder="Valor da entrega"
                        maxLength={16}
                        decimalSeparator=","
                        thousandSeparator="."
                        fixedDecimalScale
                        decimalScale={2}
                        prefix={"R$"}
                        onValueChange={(values, sourceInfo) => {
                          setValueDelivery(Number(values.value).toFixed(2));
                        }}
                      />
                    )}
                  </DivOrgNewComplement>
                  <DivOrgNewDistrict>
                    <NewDistrict
                      placeholder="Bairro"
                      value={registerLocal.district}
                      onChange={(e) =>
                        setRegisterLocal({
                          ...registerLocal,
                          district: e.target.value,
                        })
                      }
                    />
                    <NewCity
                      placeholder="Cidade"
                      value={registerLocal.city}
                      onChange={(e) =>
                        setRegisterLocal({
                          ...registerLocal,
                          city: e.target.value,
                        })
                      }
                    />
                    <SelectOptionNewState
                      value={registerLocal.state}
                      onChange={(e) =>
                        setRegisterLocal({
                          ...registerLocal,
                          state: e.target.value,
                        })
                      }
                    >
                      <Options value="">Selecione</Options>
                      {infoState.map((infoStates, index) => {
                        return (
                          <Options key={index} value={infoStates.value}>
                            {infoStates.value}
                          </Options>
                        );
                      })}
                    </SelectOptionNewState>

                    <BtnAddLocal
                      type="button"
                      onClick={() => {
                        registerNewLocal();

                        createNewDelivery();
                      }}
                    >
                      Adicionar
                    </BtnAddLocal>
                  </DivOrgNewDistrict>
                </DivOrgInputs>
              </DivFormDelivery>
            </DivOrgClientInfo>
          ) : (
            <DivOrgNotDelivery>
              <InfoNotDelivery>Cliente irá retirar na loja.</InfoNotDelivery>
            </DivOrgNotDelivery>
          )}
          {loadingDelivery ? (
            <DivOrgLoading>
              <ClipLoader speedMultiplier={3} color={"#000"} />
              <LoadingInfo>Calculando Valor da Entrega</LoadingInfo>
            </DivOrgLoading>
          ) : (
            infoDeliveryValue?.codeStatus === 404 && (
              <DivOrgResults>
                <InfoResult>{infoDeliveryValue?.message}</InfoResult>
              </DivOrgResults>
            )
          )}
        </DivLocal>
        <DivTotal>
          <TitleCloseOrder>Resumo</TitleCloseOrder>
          <DivOrder>
            <DivOrgOrder>
              {/* Altera o valor da entrega apos encontrar o local */}
              <TitleOrder>Entrega</TitleOrder>
              <NumericFormat
                customInput={PriceOrder}
                displayType="text"
                value={valueToDelivery || 0}
                decimalSeparator=","
                thousandSeparator="."
                fixedDecimalScale
                decimalScale={2}
                prefix={"R$ "}
              />
            </DivOrgOrder>
            <DivOrgOrder>
              {/* Valor total do pedido sem a entrega */}
              <TitleOrder>Pedido</TitleOrder>
              <NumericFormat
                customInput={PriceOrder}
                displayType="text"
                value={infoOrder.valueOrder}
                decimalSeparator=","
                thousandSeparator="."
                fixedDecimalScale
                decimalScale={2}
                prefix={"R$ "}
              />
              {/* <PriceOrder>R$ {watch("valueOrder") || 0}</PriceOrder> */}
            </DivOrgOrder>
            <DivOrgSelect>
              <TitleOrder>Desconto ?</TitleOrder>
              <SelectDescont
                {...register("discountOption", {
                  required: true,
                })}
                onChange={(e) => handleChangeDescont(e)}
              >
                <OptionsPay value="">Selecione</OptionsPay>
                <OptionsPay value="sim">Sim</OptionsPay>
                <OptionsPay value="nao">Não</OptionsPay>
              </SelectDescont>
            </DivOrgSelect>
            {showDescont === "sim" && (
              <DivOrgOrder>
                <TitleOrder>Desconto</TitleOrder>
                <NumericFormat
                  customInput={ChangeInput}
                  decimalSeparator="."
                  decimalScale={2}
                  placeholder="%"
                  suffix="%"
                  isAllowed={(values) => {
                    if (!values.value) return true;
                    const { floatValue } = values;
                    return floatValue <= 100;
                  }}
                  onValueChange={(values) => {
                    setPercentDiscount(values.value);
                    setValue("percentDiscount", parseInt(values.value));
                  }}
                />
              </DivOrgOrder>
            )}
            <Line />
            <DivOrgOrder>
              {/* Soma do valor da entrega com total do pedido */}
              <TitleOrder>Total</TitleOrder>
              <NumericFormat
                customInput={PriceOrder}
                displayType="text"
                value={watch("valueWithDiscount")}
                decimalSeparator=","
                thousandSeparator="."
                fixedDecimalScale
                decimalScale={2}
                prefix={"R$ "}
              />
            </DivOrgOrder>
            <DivOrgOrder>
              <TitleOrder>Forma de Pagamento</TitleOrder>
              <DivOptions>
                {/* Opções para pagamento */}
                <SelectOptionsToPay
                  {...register("formPayment", {
                    required: true,
                  })}
                  onChange={(e) => handleChangeValue(e)}
                >
                  <OptionsPay value="">Selecione</OptionsPay>
                  <OptionsPay value="dinheiro">Dinheiro</OptionsPay>
                  <OptionsPay value="debito">Debito</OptionsPay>
                  <OptionsPay value="credito">Credito</OptionsPay>
                </SelectOptionsToPay>
                {/* Digita o valor a pagar em dinheiro */}
              </DivOptions>
            </DivOrgOrder>
            {showChange === "dinheiro" && (
              <DivInputChange>
                <DivOrgValuePay>
                  <ChangeLabel>Valor Pago</ChangeLabel>
                  <NumericFormat
                    maxLength={16}
                    customInput={ValuePay}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale
                    decimalScale={2}
                    prefix={"R$"}
                    onValueChange={(values, sourceInfo) => {
                      setValuePayed(values.value);
                    }}
                  />
                </DivOrgValuePay>
                <DivOrgChangeValue>
                  {/* Subtrai o valor em caso de pagamento em dinheiro */}
                  <TitleOrder>Troco</TitleOrder>
                  <NumericFormat
                    customInput={PriceOrder}
                    displayType="text"
                    value={watch("valueChange")}
                    decimalSeparator=","
                    thousandSeparator="."
                    fixedDecimalScale
                    decimalScale={2}
                    prefix={"R$ "}
                  />
                </DivOrgChangeValue>
              </DivInputChange>
            )}

            <BtnFinish type="submit">Finalizar</BtnFinish>
            <FinishOrderAnimation
              showAnimation={showAnimation}
              setShowAnimation={setShowAnimation}
              trueAnimation={trueAnimation}
              setTrueAnimation={setTrueAnimation}
              infoFinishOrder={infoFinishOrder}
              printDelivery={printDelivery}
            />
          </DivOrder>
        </DivTotal>
      </FormFinal>
    </DivCloseOrder>
  );
}
