import React from "react";
import NavBar from "../../../components/NavBar/Navbar";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRouter = ({ children, requiredPermissions }) => {
  const infoAuth = useSelector((state) => state.auth);

  const infoPermission = [];

  infoAuth.permissions.forEach((permissions) => {
    infoPermission.push(permissions);
  });

  if (!infoAuth.isAuthenticated) {
    return <Navigate to="/" />;
  }

  const userWithPermission = requiredPermissions.some((permissions) => {
    return infoPermission.includes(permissions);
  });

  return userWithPermission ? (
    <>
      <NavBar />
      {children}
    </>
  ) : (
    <>
      <NavBar />
      <Navigate to="/not-permission" state={{ infoAuth: infoAuth }} />
    </>
  );
};

export default PrivateRouter;
