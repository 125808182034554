import styled from "styled-components";
import { Colors, Phone_media, Tablet_media } from "../../variable";

export const DivStockItem = styled.div`
  width: 1100px;
  margin: auto;

  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;

  background-color: ${Colors.BackgroundColors.BkDiv};
  border-radius: 25px;
  padding: 15px;

  ${Phone_media.Phone_column}
  ${Tablet_media.Tablet_column}
`;
export const DivScreenStockItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  /* align-items: center; */
  padding: 10px;

  ${Phone_media.Phone_column}
  ${Tablet_media.Tablet_row}
`;
export const TitleStockItem = styled.h1`
  font-size: 20px;
  display: flex;
  justify-content: center;
  margin: 10px auto;
`;
