import styled from "styled-components";
import { Colors, Phone_media, Tablet_media } from "../../variable";

export const DivProductRegister = styled.div`
  width: 1100px;
  height: 600px;
  margin: auto;

  display: flex;
  justify-content: center;
  flex-direction: column;

  background-color: ${Colors.BackgroundColors.BkDiv};
  border-radius: 25px;
  padding: 5px;

  ${Phone_media.Phone_column};
  @media screen and (min-width: 769px) and (max-width: 1024px){
    width: 95%;
    align-items: center;
  }
`;

export const TitleProduct = styled.h1`
  font-size: 20px;
  display: flex;
  justify-content: center;
  margin: 10px auto;
`;

export const DivScreenProduct = styled.div`
  height: 100%;
  display: flex;
  padding: 5px;
  flex-direction: row;
  justify-content: space-between;

  ${Phone_media.Phone_column}
    /* ${Tablet_media.Tablet_row} */
`;


