import React, { useState } from "react";
import {
  DivOrgCardDelete,
  DivOrgBtnClose,
  BtnClose,
  DivOrgLabel,
  DivOrgBtn,
  BtnConfirm,
  BtnCancel,
  LabelDelete,
  DivOrgDelete,
  InfoResult,
  DivOrgResults,
  DivOrgLoading,
} from "./DeleteDeliveryStyle";
import { Close } from "@styled-icons/material";
import { downDelivery } from "../../../store/registers/delivery/delivery.actions";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";

export default function DeleteDelivery(props) {
  const deliveryRemove = props.selectedDelivery;
  const dispatch = useDispatch();
  const [deleteDeliveryInfo, setDeleteDeliveryInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  const deleteDelivery = async (dataDelivery) => {
    setLoading(true);
    const idDelivery = dataDelivery.idDelivery;
    const deleteDelivery = await dispatch(downDelivery(idDelivery));
    setDeleteDeliveryInfo(deleteDelivery.payload);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    setTimeout(() => {
      props.setLoadingDeliverys(true);
      props.setDelDeliveryOption(false)
    }, 4000);
  };

  return (
    <DivOrgDelete>
      <DivOrgBtnClose>
        <BtnClose onClick={() => props.setDelDeliveryOption(false)}>
          <Close />
        </BtnClose>
      </DivOrgBtnClose>
      <DivOrgCardDelete showDiv={props.delDeliveryOption}>
        <DivOrgLabel>
          <LabelDelete>
            Deseja apagar o bairro ({deliveryRemove.districtName}) para entregas
          </LabelDelete>
        </DivOrgLabel>
        <DivOrgBtn>
          <BtnConfirm onClick={() => deleteDelivery(deliveryRemove)}>
            Sim
          </BtnConfirm>
          <BtnCancel onClick={() => props.setDelDeliveryOption(false)}>
            Não
          </BtnCancel>
        </DivOrgBtn>
      </DivOrgCardDelete>
      {loading ? (
        <DivOrgLoading>
          <ClipLoader speedMultiplier={3} />
        </DivOrgLoading>
      ) : (
        (deleteDeliveryInfo.errorStatus && (
          <DivOrgResults>
            <InfoResult>{deleteDeliveryInfo.message}</InfoResult>
          </DivOrgResults>
        )) ||
        (deleteDeliveryInfo.successStatus && (
          <DivOrgResults>
            <InfoResult>{deleteDeliveryInfo.message}</InfoResult>
          </DivOrgResults>
        ))
      )}
    </DivOrgDelete>
  );
}
