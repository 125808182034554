import React, { useEffect, useState } from "react";
import {
  Close,
  DeleteForever,
  Edit,
  Search,
} from "@styled-icons/material";
import { ClipLoader } from "react-spinners";
import {
  BtnEdit,
  BtnRemove,
  BtnSearch,
  DivBtnEdit,
  DivBtnSearch,
  DivIdBrand,
  DivBrand,
  DivBrandInfo,
  DivSearch,
  DivSearchBrand,
  DivTableSearch,
  DivInfo,
  TitleSearchBrand,
  NameInput,
  NameLabel,
  SpanCod,
  SpanName,
  DivOrgLoading,
  DivOrgCard,
  CodInput,
  DivBtnFilter,
  BtnCancel,
} from "./SearchBrandStyle";
import UpdateBrand from "../../Update/UpdateBrand/UpdateBrand";
// import InfoBrand from "../Info/InfoBrand/InfoBrand";
import DeleteBrand from "../../DeleteComponent/DeleteBrand/DeleteBrand";

export default function SearchBrand(props) {
  const brandListInfo = props.brandsInfo;
  
  const [brandPopUp, setBrandPopUp] = useState(false);
  const [delBrandOption, setDelBrandOption] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState();

  const [filterCodBrand, setFilterCodBrand] = useState("");
  const [filterNameBrand, setFilterNameBrand] = useState("");
  const [filterInfoBrand, setFilterInfoBrand] = useState([]);

  const [showList, setShowList] = useState(false);

  const [loading, setLoading] = useState();
  const [loadingBrands, setLoadingBrands] = useState(false);
  const [dataBrandUpdate, setDataBrandUpdate] = useState();

  const createListBrand = (dataBrand) => {
    setLoading(true);
    setShowList(true);
    if (showList) {
      setFilterInfoBrand(dataBrand);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  // ! - usar para formatar dois nomes Ex. (henrique silva)
  const parseName = (oneName, secondName) => {
    const firstName = oneName || "";
    const lastName = secondName || "";
    var fullName = "";
    if (lastName.length > 0) {
      fullName = firstName.concat(" ", lastName);
    } else {
      fullName = firstName;
    }
    const formatName = fullName?.split(" ");
    for (var i = 0; i < formatName?.length; i++) {
      formatName[i] =
        formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
    }
    let result = formatName?.join(" ");
    return result;
  };

  const filterBrand = () => {
    const filterList = brandListInfo
      .filter((brand) =>
        filterNameBrand.length > 0
          ? parseName(brand.brandName).includes(parseName(filterNameBrand))
          : brand
      )
      .filter((brand) =>
        filterCodBrand > 0 ? brand.idBrand === filterCodBrand : brand
      );

    setFilterInfoBrand(filterList);
  };

  useEffect(() => {
    createListBrand(brandListInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandListInfo]);

  useEffect(() => {
    setLoading(true);
    if (loadingBrands) {
      props.getListBrands();
    }
    setTimeout(() => {
      setLoadingBrands(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingBrands]);

  return (
    <DivSearchBrand>
      <DivSearch>
        <TitleSearchBrand>Consulta Marca</TitleSearchBrand>
        <DivBtnFilter show={props.disableFilter}>
          <NameLabel>Marca</NameLabel>
          <NameInput
            value={filterNameBrand}
            onChange={(e) => setFilterNameBrand(e.target.value)}
          />
          <NameLabel>Codigo</NameLabel>
          <CodInput
            value={filterCodBrand}
            onValueChange={(values, sourceInfo) => {
              setFilterCodBrand(parseInt(values.value));
            }}
          />
          <DivBtnSearch>
            <BtnSearch type="button" onClick={filterBrand}>
              <Search />
            </BtnSearch>
            <BtnCancel
              type="button"
              onClick={() => {
                setFilterCodBrand("");
                setFilterNameBrand("");
                setFilterInfoBrand(brandListInfo);
              }}
            >
              <Close />
            </BtnCancel>
          </DivBtnSearch>
        </DivBtnFilter>
      </DivSearch>
      <DivTableSearch>
        {loading ? (
          <DivOrgLoading>
            <ClipLoader speedMultiplier={3} color={"#FFF"} />
          </DivOrgLoading>
        ) : (
          filterInfoBrand.map((infoBrand, index) => {
            return (
              <DivBrand key={infoBrand.idBrand}>
                <DivOrgCard>
                  <DivInfo>
                    <DivIdBrand>{index + 1}</DivIdBrand>
                    <DivBrandInfo>
                      <SpanName>{parseName(infoBrand.brandName)}</SpanName>
                      <SpanCod>Codigo: {infoBrand.idBrand}</SpanCod>
                    </DivBrandInfo>
                  </DivInfo>
                  <DivBtnEdit>
                    <BtnEdit
                      onClick={() => {
                        setBrandPopUp(!brandPopUp);
                        setDataBrandUpdate(infoBrand);
                      }}
                    >
                      <Edit />
                    </BtnEdit>
                    {brandPopUp &&
                      infoBrand.idBrand === dataBrandUpdate.idBrand && (
                        <UpdateBrand
                          setLoadingBrands={setLoadingBrands}
                          dataBrandUpdate={dataBrandUpdate}
                          brandPopUp={brandPopUp}
                          setBrandPopUp={setBrandPopUp}
                        />
                      )}
                    <BtnRemove
                      type="button"
                      onClick={() => {
                        setDelBrandOption(!delBrandOption);
                        setSelectedBrand(infoBrand);
                      }}
                    >
                      <DeleteForever />
                    </BtnRemove>
                  </DivBtnEdit>
                </DivOrgCard>
                {delBrandOption &&
                  infoBrand.idBrand === selectedBrand.idBrand && (
                    <DeleteBrand
                      setLoadingBrands={setLoadingBrands}
                      selectedBrand={selectedBrand}
                      delBrandOption={delBrandOption}
                      setDelBrandOption={setDelBrandOption}
                    />
                  )}
              </DivBrand>
            );
          })
        )}
      </DivTableSearch>
    </DivSearchBrand>
  );
}
