import { combineReducers } from "redux";
import allProviderSlice from "./registers/provider/provider.reducers";
import authSlice from "./auth/auth.reducers";
import brandSlice from "./registers/brand/brand.reducers";
import cashierSlice from "./financial/cashier/cashier.reducers";
import clientSlice from "./registers/clients/clients.reducers";
import expenseSlice from "./financial/expense/expense.reducers";
import finishOrderSlice from "./commercial/finishOrder.reducers";
import homeSlice from "./home/home.reducers"
import itemEntryStockSlice from "./stock/itemEntryStock/newItemStock.reducer";
import itemOutStockSlice from "./stock/ItemOutStock/itemOut.reducer";
import infoExpenseSlice from "./infoCompany/expense/expense.reducers";
import infoInvoiceSlice from "./infoCompany/invoice/invoice.reducers"
import infoProfitSlice from "./infoCompany/profit/profit.reducers"
import infoStockSlice from "./infoCompany/stock/infoStock.reducers"
import productSlice from "./registers/products/products.reducers";
import resumeClientSlice from "./financial/resumeClient/resumeClient.reducers";
import resumeFinancialSlice from "./financial/resumeFinancial/resumeFinancial.reducer";
import resumeProvidersSlice from "./financial/resumeProviders/resumeProviders.reducer";
import resumeEntryOrders from "./financial/resumeEntryOrders/resumeEntryOrders.reducer";
import stockNowSlice from "./stock/stockNow/stockNow.reducers";
import usersSlice from "./registers/users/user.reducers";

export default combineReducers({
  auth: authSlice,
  brand: brandSlice,
  cashier: cashierSlice,
  clients: clientSlice,
  commercial: finishOrderSlice,
  entryOrders: resumeEntryOrders,
  expense: expenseSlice,
  home: homeSlice,
  itemEntryStock: itemEntryStockSlice,
  itemOutStock: itemOutStockSlice,
  infoExpense: infoExpenseSlice,
  infoInvoice: infoInvoiceSlice,
  infoProfit: infoProfitSlice,
  infoStock: infoStockSlice,
  products: productSlice,
  providers: allProviderSlice,
  resumeClient: resumeClientSlice,
  resumeFinancial: resumeFinancialSlice,
  resumeProviders: resumeProvidersSlice,
  stockNow: stockNowSlice,
  users: usersSlice,
});
